import * as React from 'react';
import Header from './Header';

interface IUnauthorizedProps { }

const Unauthorized: React.FunctionComponent<IUnauthorizedProps> = (props) => {
    return (
        <>
            <div className="header-wrapper">
                <Header />
            </div>
            <div className="row mx-5 my-5">
                <div className="unauthorized-fix colored-top">
                    <div className="d-flex filters-summary">
                        <h2>Access Denied</h2>
                    </div>
                    <div className="d-flex filters-summary">
                        <p>Please contact INRO Support Team</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Unauthorized;