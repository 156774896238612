import { useEffect, useState, useContext } from 'react';
import httpClient from '../../Common/Hooks/useHttpClient';
import VersionInformationInfo from '../Entities/VersionInformationInfo';

const useGetVersionInfo = (): [VersionInformationInfo] => {
    const [versionInformationInfo, setVersionInformationInfo] = useState<VersionInformationInfo>({} as VersionInformationInfo)

    useEffect(() => {
        httpClient('')
            .post('/api/data/GetVersionInformation', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                if (c.data !== null) {
                    setVersionInformationInfo(c.data);
                    return;
                }
                setVersionInformationInfo({} as VersionInformationInfo);
            })
            .catch((c) => {
                setVersionInformationInfo({} as VersionInformationInfo);
            });
        setVersionInformationInfo({} as VersionInformationInfo);
    }, []);
    return [versionInformationInfo];
};

export default useGetVersionInfo;