import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import httpClient from './useHttpClient';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import useGetApiUrls from './useGetApiUrls';
import OnLineApplicationInfo from '../Entities/OnLineApplicationInfo';

const useGetMyApplication = (studentID: number, setLoading: Dispatch<SetStateAction<boolean>>): [OnLineApplicationInfo, Dispatch<SetStateAction<OnLineApplicationInfo>>] => {
    const { session } = useContext(SessionContext);
    const { getMyApplicationUrl } = useGetApiUrls();

    const [applicationInfo, setApplicationInfo] = useState<OnLineApplicationInfo>({} as OnLineApplicationInfo);

    useEffect(() => {
        setLoading(true);
        httpClient(session.apiToken)
            .post(getMyApplicationUrl, studentID, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                setApplicationInfo(response.data);
                setLoading(false)
            })
            .catch((ex) => {
                setLoading(false)
                console.log(ex);
            })
        !studentID && setApplicationInfo({} as OnLineApplicationInfo);
    }, [studentID]);

    return [applicationInfo, setApplicationInfo];
}

export default useGetMyApplication;