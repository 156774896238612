import { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import httpClient from './useHttpClient';
import LookupMaster from '../Entities/LookupMaster';

const useGetSchoolNames = (url: string): LookupMaster[] => {
    const { session } = useContext(SessionContext);
    const [schoolname, setSchoolname] = useState<LookupMaster[]>([] as LookupMaster[])

    useEffect(() => {
        httpClient(session.apiToken)
            .get(url)
            .then((c) => {
                setSchoolname(c.data)
            })
            .catch((c) => { });
    }, []);

    return schoolname;
};

export default useGetSchoolNames;