import { useContext, useState, useEffect } from 'react';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import LookupMaster from '../Entities/LookupMaster';
import httpClient from './useHttpClient';

const useGetLookupMaster = (url: string,onlyActive?: boolean): LookupMaster[] => {
    const { session } = useContext(SessionContext);
    const [lookupMaster, setLookupMaster] = useState<LookupMaster[]>([] as LookupMaster[])

    useEffect(() => {
        httpClient(session.apiToken)
            .get(url)
            .then((c) => {
                if(c.data && onlyActive)
                    setLookupMaster(c.data.filter((c:LookupMaster) => c.isActive))
                else
                    setLookupMaster(c.data)
            })
            .catch((c) => { });
    }, []);

    return lookupMaster;
};

export default useGetLookupMaster;