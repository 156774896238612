import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import httpClient from './useHttpClient';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import useGetApiUrls from './useGetApiUrls';
import OnLineApplicationInfo from '../Entities/OnLineApplicationInfo';
import { ApplicationStatus } from '../Enums/Enums';
import { useNavigate } from 'react-router-dom';

const useSaveApplication = (): [(onLineApplicationInfo: OnLineApplicationInfo, isSubmit: boolean) => void, boolean] => {
    let navigate = useNavigate();

    const { session, setAlertMessage } = useContext(SessionContext);
    const { getSaveApplicationUrl } = useGetApiUrls();
    const [isSending, setIsSending] = useState(false);

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const saveProfile = useCallback(
        (onLineApplicationInfo: OnLineApplicationInfo, isSubmit: boolean) => {

            httpClient(session.apiToken)
                .post(getSaveApplicationUrl, onLineApplicationInfo, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    if (onLineApplicationInfo.applicationStatus === ApplicationStatus.Submitted) {
                        navigate('/Dashboard', { replace: true });

                        setAlertMessage({ ...session, alertMessage: { type: 'success', message: 'Application was submitted' } });
                    } else {
                        setAlertMessage({ ...session, alertMessage: { type: 'success', message: 'Application saved' } });
                    }
                })
                .catch((ex) => {
                    setAlertMessage({ ...session, alertMessage: { type: 'error', message: 'Error saving application' } });
                    console.log(ex);
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );

    return [saveProfile, isSending];
};

export default useSaveApplication;