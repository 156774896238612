import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import RichTextField from '../../Common/Components/RichTextField';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import ValidationError from '../../Common/Entities/ValidationError';
import { FormHelperText } from '@mui/material';

interface IDiversityStatementProps {
    data: OnLineApplicationInfo;
    setData: Dispatch<SetStateAction<OnLineApplicationInfo>>
    validationErrors: boolean;
    errors: ValidationError[];
    validate: any;
}

const DiversityStatement: React.FunctionComponent<IDiversityStatementProps> = ({ data, setData, validationErrors, errors, validate }) => {

    return (
        <>
            <div>
                <div className="gray-box">

                    <p className='mb-0'><span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span> Please describe (in 2000 words or less) your experiences and commitment to diversity and how your participation in INRO will advance the NIAID goal of promoting diversity and inclusions in biomedical research.</p>
                </div>
                <RichTextField id={'diversityStatement'} value={data.diversityStatement} data={data} setData={setData} validate={validate} />
                {validationErrors && errors[13].message && (
                    <FormHelperText className="text-danger">
                        {errors[13].message}
                    </FormHelperText>
                )}
            </div>
        </>
    );
};

export default DiversityStatement;