import React, { useState } from "react";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./App/Common/Components/Footer";
import Header from "./App/Common/Components/Header";
import Unauthorized from "./App/Common/Components/Unauthorized";
import { SessionContext } from "./App/Common/Contexts/SessionContext/SessionContext";
import useGetOTPSession from "./App/Common/Hooks/useGetOTPSession";
import useGetSessionInfo from "./App/Common/Hooks/useGetSessionInfo";
import useGetVersionInfo from "./App/Common/Hooks/useGetVersionInfo";
import useGetIsDevMode from "./App/Common/Hooks/useGetIsDevMode";
import Dashboard from "./App/Home/Components/Dashboard";
import RequireAuth from "./App/Common/Components/RequiredAuth";
import "./App/Static/Styles/scss/main.scss";
import OnlineApplicationForm from "./App/Application/Components/OnlineApplicationForm";
import Toast from "./App/Common/Components/Toast";
import ApplicationView from "./App/Application/Components/ApplicationView";
import analytics from "./Services/analytics";
import useInitiateGoogleAnlaytics from "./App/Common/Hooks/useInitiateGoogleAnlaytics";

function App() {
  // pull the session information
  const [isAuthenticated, isLoading, isUnauthorized] = useGetOTPSession();
  //const [isAuthenticated, isLoading] = useGetSessionInfo();
  useInitiateGoogleAnlaytics(analytics);
  const [isDevMode] = useGetIsDevMode();

  const [versionInformationInfo] = useGetVersionInfo();
  const { session, setSession } = useContext(SessionContext);

  return (
    <>
      <Toast />
      <div className="App">
        <div className="jumbotron jumbotron-header">
          <div className="header-wrapper">
            <Header />
          </div>
        </div>

        <Routes>
          <Route
            element={
              <RequireAuth
                isLoading={isLoading}
                isAuthendiated={isAuthenticated}
              />
            }
          >
            <Route path="/otpLogin" element={<Dashboard />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/Dashboard" element={<Dashboard />} />
            <Route path="/Application" element={<OnlineApplicationForm />} />
            <Route path="/ApplicationView" element={<ApplicationView />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Route>
        </Routes>

        <Footer versionInformationInfo={versionInformationInfo} />
      </div>
    </>
  );
}

export default App;
