import * as React from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface IBackDropProps {
    open: boolean;
}

const BackDrop: React.FunctionComponent<IBackDropProps> = ({ open}) => {
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}>
                <CircularProgress />
            </Backdrop>
        </>
    );
};

export default BackDrop;