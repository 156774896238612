import * as React from 'react';
import { useContext } from 'react';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import useLogout from '../Hooks/useLogout';

import logoImg from './../../Static/Images/nih-branding/logo-nih-mark.svg';
import logoTextImg from './../../Static/Images/nih-branding/logo-nih-niaid-text-white.svg';

interface IHeaderProps { }

const Header: React.FunctionComponent<IHeaderProps> = (props) => {
    const { session } = useContext(SessionContext);

    const logout = useLogout();

    const onLogout = (e: any) => {
        logout();
        e.preventDefault();
    };

    return (
        <>
            <header className="top-header">
                <div className="nih-details">
                    <a
                        href="https://www.niaid.nih.gov/"
                        rel="noreferrer"
                        target="_blank"
                        className="logo-nih"
                    >
                        <img
                            src={logoImg}
                            className="logo-nih-mark"
                            alt="National Institutes of Health (NIH) Logo"
                        />
                        <img
                            src={logoTextImg}
                            className="logo-nih-niaid-text"
                            alt="National Institute of Allergy and Infectious Diseases"
                        />
                    </a>
                    <div className="application-details">
                        <h1>INRO</h1>
                        <span>Intramural NIAID Research Oportunities</span>
                    </div>
                </div>
                {session.isAuthenticated && (
                    <div className="application-user">
                        <div className="app-user-centered">
                            <div className="help-buttons">
                              <a
                                className="mt-1 mr-2 btn-about"
                                href="https://www.niaid.nih.gov/about/inro"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                About
                              </a>

                              <a
                                className="mt-1 mr-2 btn-contact"
                                href="mailto:INRO@niaid.nih.gov"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Contact
                              </a>
                            </div>
                            <div className="user-info">
                                <span className="user-name">{session.displayName}</span>
                                <span className="user-title">{session.roleName}</span>
                            </div>
                            <div className="logout">
                                <button
                                    className="btn btn-logout"
                                    onClick={(e) => {
                                        onLogout(e);
                                    }}
                                >
                                    Logout
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </header>
        </>
    );
};

export default Header;