import * as React from 'react';
import { useContext, useRef, useState } from 'react';
import { SessionContext } from '../../Common/Contexts/SessionContext/SessionContext';
import useGetMyApplication from '../../Common/Hooks/useGetMyApplication';
import useGetInroInformation from '../../Common/Hooks/useGetInroInformation';
import SideBar from './SideBar';
import ApplicationInformationHeader from './ApplicationInformationHeader';
import ApplicationAccordion from './ApplicationAccordion';
import useGetApiUrls from '../../Common/Hooks/useGetApiUrls';
import html2canvas from 'html2canvas';
import Html2Pdf from '../../Common/Entities/Html2Pdf';
import useGetHtmlToPdf from '../../Common/Hooks/useGetHtmlToPdf';
import BackDrop from '../../Common/Components/BackDrop';


interface IApplicationViewProps {
    handleDownloadPDF?: any;
}

const ApplicationView: React.FunctionComponent<IApplicationViewProps> = ({ handleDownloadPDF }) => {
    const { session } = useContext(SessionContext);
    const [loading, setLoading] = useState(false);
    const [inroInformationInfo] = useGetInroInformation();

    const [data, setData] = useGetMyApplication(session.studentID ?? 0, setLoading);

    const { getHtmlToPdfUrl } = useGetApiUrls();

    const [isLoading, setIsLoading] = useState(false);
    const pdfref = useRef<HTMLDivElement>(null);
    const export2PDF = () => {
        // const pdf = new jsPDF('p', 'mm', 'a4');
        const forms = pdfref.current?.querySelectorAll('.pdf-form-1');

        if (!forms) {
            console.error('Forms not found');
            return;
        }

        const divElement = document.getElementById('divApplicationAccordion');
        divElement!.style.display = 'block';

        try {
            let ImagesToPdf: Array<string>;
            ImagesToPdf = [];
            let count = 0;
            Array.from(forms).forEach((form, index) => {
                html2canvas(form as HTMLDivElement, { scrollY: -window.scrollY, scale: 2 }).then((canvas) => {

                    const imageData = canvas.toDataURL('image/png');
                    ImagesToPdf.push(imageData);
                    count++;
                }).finally(() => {
                    if (count == 4) {
                        DownloadApplicationPdf(ImagesToPdf);
                    }
                });
            });
        } finally {
            divElement!.style.display = 'none';
        }
    };

    const DownloadApplicationPdf = (images64: any) => {
        let dataRequest: Html2Pdf = {
            programYear: data.programYear,
            images: images64
        }

        const fileName = `${session.displayName} - ${data.studentNumber}`;
        useGetHtmlToPdf(getHtmlToPdfUrl, session.apiToken ?? "", fileName, dataRequest, setIsLoading);
    }

    return (
        <>
            <ApplicationInformationHeader data={data} inroInformationInfo={inroInformationInfo} export2PDF={export2PDF}></ApplicationInformationHeader>

            {<BackDrop open={isLoading} />}

            <div className="main-content-wrapper">
                {inroInformationInfo && data && (
                    <div className="document-box">
                        <h3>Online Application Form (Year: {data.programYear})</h3>
                        <ApplicationAccordion data={data}></ApplicationAccordion>
                    </div>
                )}
                {/* <SideBar isView={true}></SideBar> */}
            </div>

            <div style={{ 'width': '600px' }}>
                    <div id="divApplicationAccordion" style={{ 'display': 'none' }} ref={pdfref} className="document-box">
                        {data && (
                            <>
                                <div>
                                    <h3 className='pdf-text'>Online Application Form (Year: {data.programYear})</h3>
                                    <ApplicationAccordion data={data}></ApplicationAccordion>
                                </div>
                            </>
                        )}
                    </div>
                </div>

        </>
    );
};

export default ApplicationView;