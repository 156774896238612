import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import httpClient from './useHttpClient';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import useGetApiUrls from './useGetApiUrls';

const useCopyApplication = (): [(studentID: number, isSubmit: boolean) => void, boolean] => {
    const { session, setAlertMessage } = useContext(SessionContext);
    const { getCopyApplicationUrl } = useGetApiUrls();
    const [isSending, setIsSending] = useState(false);

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);
 
    const copyApplication = useCallback(
        (studentID: number, isSubmit: boolean) => {
 
            httpClient(session.apiToken)
                .post(getCopyApplicationUrl, studentID, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                .then(response => {
                    setAlertMessage({ ...session, alertMessage: { type: 'success', message: 'Application was copied successfully' } });
            })
                .catch((ex) => {
                    setAlertMessage({ ...session, alertMessage: { type: 'error', message: 'Error copying application' } });
                    console.log(ex);
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setIsSending(false);
                    }
                });
        }, [isSending]
    );

    return [copyApplication, isSending];
};

export default useCopyApplication;