import * as React from 'react';
import useGetOtpUrl from '../../Common/Hooks/useGetOtpUrl';
import useGetInroInformation from '../../Common/Hooks/useGetInroInformation';
import useGetOTPSessionTest from '../../Common/Hooks/useGetOTPSessionTest';
import { Button, FormControl, TextField } from '@mui/material';
import arrowSrc from '../../Static/Images/icons/banner-arrow.svg';
import useGetIsDevMode from '../../Common/Hooks/useGetIsDevMode';
import OtpUser from '../../Common/Entities/OtpUser';
import { useEffect, useState } from 'react';
import useGetOtpSiteUrl from '../../Common/Hooks/useGetOtpSiteUrl';

interface ILoginProps {
    isWelcome: boolean;
}

const Login: React.FunctionComponent<ILoginProps> = ({ isWelcome }) => {
    const [isDevMode] = useGetIsDevMode();
    const [getOTPSessionTest, isLoading, isAuthenticated, isUnauthorized] = useGetOTPSessionTest();
    const [data, setData] = useState<OtpUser>({} as OtpUser);

    const [otpUrl] = useGetOtpUrl();
    const [otpSiteUrl] = useGetOtpSiteUrl();

    const [inroInformationInfo] = useGetInroInformation();

    let onlineSubmissionOpenDate = inroInformationInfo && inroInformationInfo.onlineSubmissionOpenDate ? new Date(inroInformationInfo.onlineSubmissionOpenDate).toLocaleDateString() : '';

    const [inroStatus, setInroStatus] = useState(4);
    useEffect(() => {
        if (inroInformationInfo.isOnlineSubmissionAllowed) {
            setInroStatus(1);
        } else if (inroInformationInfo.inroYear === null) {
            setInroStatus(2);
        } else if (inroInformationInfo.isOnlineSubmissionCompleted) {
            setInroStatus(3);
        }
    }, [inroInformationInfo]);

    const otpLogin = (e: any) => {
        window.location.href = otpUrl;
        e.preventDefault();
    }

    const otpLoginDev = (e: any) => {
        e.preventDefault();
        getOTPSessionTest(data);
    }

    let currentUrl = document.referrer.replace(/\/$/, '');

    useEffect(() => {
        if (!isAuthenticated && document.referrer !== '' && currentUrl === otpSiteUrl) {
            const element = document.getElementById('ecps-login-btn') as HTMLElement;

            if (element !== null && element !== undefined) {
                element.click();
            }
        }
    });




    return (
        <>
            {!isAuthenticated && (
                <div className="login-page">
                    <div className="container-fluid">
                        <div className="main-content">
                            {inroInformationInfo && inroStatus === 1 && (
                                <>
                                    <h1>Welcome! You are invited to fill out the <b>INRO {inroInformationInfo.inroYear} Application</b></h1>
                                    <span>Thank you for your interest in the National Institutes of Health – Intramural NIAID Research Opportunities (INRO) program. </span>
                                    <p> But you can learn more about the program here: <a href="https://www.niaid.nih.gov/about/inro" target="_blank">https://www.niaid.nih.gov/about/inro</a></p>

                                    <div className="login-box">
                                        <div className="student-account">
                                            <h6>Are you a student without an account?</h6>
                                            <p>Before you create a new account, you Must be a U.S. Citizen or a Permanent Resident. <a href="https://www.niaid.nih.gov/about/inro#eligibility" target="_blank">Please see our eligibility requirements for details.</a> If you don't meet the requirements, your application will not be accepted.</p>
                                        </div>
                                        <div className="employee-account">
                                            <h6>Student Login</h6>
                                            <div className="homepage-jumbotron">
                                                <div className="jumbotron-lg-content">
                                                    <div className="jumbotron-text internal-login">
                                                        <div className="jumbotron-actions">
                                                            <div className="jumbotron-action">
                                                                <div>
                                                                    <Button className="btn-guide"
                                                                        type="button"
                                                                        variant="contained"
                                                                        id="ecps-login-btn"
                                                                        onClick={otpLogin}
                                                                    >
                                                                        Student Login
                                                                        <div className="chevron-right">
                                                                            <img src={arrowSrc} alt="Login" />
                                                                        </div>
                                                                    </Button>
                                                                    <div className="mt-3" >
                                                                        <a href={otpUrl}>Register new account</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {isDevMode && !isAuthenticated && (
                                            <div className="employee-account">
                                                <h6>Localhost Login</h6>
                                                <div className="col-lg-12">

                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <FormControl className="col-md-3">
                                                                <TextField id="standard-basic"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label="Email"
                                                                    fullWidth
                                                                    value={data.email ?? ''}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    onChange={(e) => setData({ ...data, email: e.target.value })}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">

                                                            <FormControl className="col-md-3">
                                                                <TextField id="standard-basic"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label="First Name"
                                                                    fullWidth
                                                                    value={data.firstName ?? ''}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    onChange={(e) => setData({ ...data, firstName: e.target.value })}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col">

                                                            <FormControl className="col-md-3">
                                                                <TextField id="standard-basic"
                                                                    InputLabelProps={{ shrink: true }}
                                                                    label="Last Name"
                                                                    fullWidth
                                                                    value={data.lastName ?? ''}
                                                                    inputProps={{ maxLength: 50 }}
                                                                    onChange={(e) => setData({ ...data, lastName: e.target.value })}
                                                                />
                                                            </FormControl>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Button className="btn-guide"
                                                            type="button"
                                                            variant="contained"
                                                            id="ecps-login-btn"
                                                            onClick={otpLoginDev}
                                                        >
                                                            DEV Login
                                                            <div className="chevron-right">
                                                                <img src={arrowSrc} alt="Login" />
                                                            </div>
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {inroInformationInfo && inroStatus === 2 && (
                                <>
                                    <h1>Welcome to the INRO Program.</h1>
                                    <span>Thank you for your interest in the National Institutes of Health Intramural NIAID Research Opportunities (INRO) program. We will be accepting application for INRO this Fall. </span>
                                    <p> But you can learn more about the program here: <a href="https://www.niaid.nih.gov/about/inro" target="_blank">https://www.niaid.nih.gov/about/inro</a></p>
                                </>
                            )}

                            {inroInformationInfo && inroStatus === 3 && (
                                <>
                                    <h1>Welcome to the INRO Program - INRO {inroInformationInfo.inroYear} Applications are now Closed.</h1>
                                    <span>Thank you for your interest in the National Institutes of Health Intramural NIAID Research Opportunities (INRO) program. We will be accepting application for INRO {inroInformationInfo.inroYear} this Fall. </span>
                                    <p> But you can learn more about the program here: <a href="https://www.niaid.nih.gov/about/inro" target="_blank">https://www.niaid.nih.gov/about/inro</a></p>
                                </>
                            )}

                            {inroInformationInfo && inroStatus === 4 && (
                                <>
                                    <h1>Welcome to the INRO Program - Applications for INRO {inroInformationInfo.inroYear} Open {onlineSubmissionOpenDate}</h1>
                                    <span>Thank you for your interest in the National Institutes of Health Intramural NIAID Research Opportunities (INRO) program. We will be accepting application for INRO {inroInformationInfo.inroYear} this Fall. </span>
                                    <p> But you can learn more about the program here: <a href="https://www.niaid.nih.gov/about/inro" target="_blank">https://www.niaid.nih.gov/about/inro</a></p>
                                </>
                            )}

                            <div className="warning-message">
                                <strong>Warning</strong>
                                <p>This is a United States Government computer system. The use of this system is restricted to authorized users for the processing of official U.S. Government information only. Unauthorized access, use, or modification of this computer system or of the data contained herein or in transit to/from this system constitutes a violation of Title 18, United States Code, Section 1030 and state criminal and civil laws.</p>
                                <p>These systems and equipment are subject to monitoring to ensure proper performance of applicable security features or procedures. Such monitoring may result in the acquisition, recording and analysis of all data being communicated, transmitted, processed or stored in this system by a user. If monitoring reveals possible evidence of criminal activity, such evidence may be provided to law enforcement personnel.</p>
                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default Login;