import { useContext } from 'react';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';

const useGetApiUrls = () => {
    const { session } = useContext(SessionContext);

    //Lookup
    const getlookupMasterUrl = `${session.apiUrl}Lookup/GetLookupMasters`;
    const getApplicantWorkFlowStatusUrl = `${session.apiUrl}Lookup/GetApplicantWorkFlowStatus`;
    const getSchoolNamesUrl = `${session.apiUrl}Lookup/GetSchoolNames`;

    //Student
    const getMyApplicationsUrl = `${session.apiUrl}Student/GetMyApplications`;
    const getMyApplicationUrl = `${session.apiUrl}Student/GetMyApplication`;
    const getSaveApplicationUrl = `${session.apiUrl}Student/SaveApplication`;
    const getCopyApplicationUrl = `${session.apiUrl}Student/CopyApplication`;
    const getUpdatePIIAgreementUrl = `${session.apiUrl}Student/UpdatePIIAgreement`;

    //Application
    const getHtmlToPdfUrl = `${session.apiUrl}Application/htmltopdf`;


    return {
        //Lookup
        getlookupMasterUrl,
        getApplicantWorkFlowStatusUrl,
        getSchoolNamesUrl,

        //Student
        getMyApplicationsUrl,
        getMyApplicationUrl,
        getSaveApplicationUrl,
        getCopyApplicationUrl,
        getUpdatePIIAgreementUrl,

        //Application
        getHtmlToPdfUrl
    };
};

export default useGetApiUrls;