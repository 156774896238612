import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import ValidationError from '../../Common/Entities/ValidationError';
import { DIVERSITY_STATEMENT, PERSONAL_INFORMATION, RESEARCH_INTERESTS } from '../../Common/Constants/ApplicationConstant';
import greenCheck from './../../Static/Images/icons/icon-check-green.svg';

interface IApplicationValidationProps {
    data: OnLineApplicationInfo;
    errors: ValidationError[];
}

const ApplicationValidation: React.FunctionComponent<IApplicationValidationProps> = ({ data, errors }) => {

    const personalinformationErrors = Object.values(errors).filter(x => x.section == PERSONAL_INFORMATION && x.message !== '');
    const diversityStatementErrors = Object.values(errors).filter(x => x.section == DIVERSITY_STATEMENT && x.message !== '');
    const researchinterestsErrors = Object.values(errors).filter(x => x.section == RESEARCH_INTERESTS && x.message !== '');

    return (
        <>
            {personalinformationErrors && personalinformationErrors.length === 0 &&
                diversityStatementErrors && diversityStatementErrors.length === 0 &&
                researchinterestsErrors && researchinterestsErrors.length === 0 && (
                    <div className="box-border-top mb-5">
                        <p><b>Application validated without errors</b></p>
                        <img
                            src={greenCheck}
                            className="logo-nih-mark"
                            alt="Application validated without errors"
                        />
                    </div>
                )}


            {personalinformationErrors && personalinformationErrors.length > 0 && (
                <div className="box-border-top mb-5">
                    <h5><span className="num-warning">{personalinformationErrors.length ?? 0}</span>Personal Information</h5>
                    <p><b>The following information is not entered in the above section. Please provide those information and complete the application</b></p>
                    <ul>
                        {personalinformationErrors.map((option, index) => (
                            <li key={index}>{option.label}</li>
                        ))}
                    </ul>
                </div>
            )}

            {diversityStatementErrors && diversityStatementErrors.length > 0 && (
                <div className="box-border-top mb-5">
                    <h5><span className="num-warning">{diversityStatementErrors.length ?? 0}</span>Diversity Statement</h5>
                    <p><b>The following information is not entered in the above section. Please provide those information and complete the application</b></p>
                    <ul>
                        {diversityStatementErrors.map((option, index) => (
                            <li key={index}>{option.label}</li>
                        ))}
                    </ul>
                </div>
            )}

            {researchinterestsErrors && researchinterestsErrors.length > 0 && (
                <div className="box-border-top mb-5">
                    <h5><span className="num-warning">{researchinterestsErrors.length ?? 0}</span>Research Interest</h5>
                    <p><b>The following information is not entered in the above section. Please provide those information and complete the application</b></p>
                    <ul>
                        {researchinterestsErrors.map((option, index) => (
                            <li key={index}>{option.label}</li>
                        ))}
                    </ul>
                </div>
            )}
        </>
    );
};

export default ApplicationValidation;