import * as React from 'react';
import { Backdrop, Button, Fade, Modal } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import httpClient from '../../Common/Hooks/useHttpClient';
import useGetApiUrls from '../../Common/Hooks/useGetApiUrls';
import { SessionContext } from '../../Common/Contexts/SessionContext/SessionContext';
import { useNavigate } from 'react-router-dom';
import useGetMyApplication from '../../Common/Hooks/useGetMyApplication';
import ApplicationAccordion from './ApplicationAccordion';
import html2canvas from 'html2canvas';
import Html2Pdf from '../../Common/Entities/Html2Pdf';
import useGetHtmlToPdf from '../../Common/Hooks/useGetHtmlToPdf';
import ApplicationInfo from '../../Common/Entities/ApplicationInfo';
import BackDrop from '../../Common/Components/BackDrop';

interface IExportPdfProps {
    studentID: number;
}

const ExportPdf: React.FunctionComponent<IExportPdfProps> = ({ studentID }) => {
    const { session } = useContext(SessionContext);
    const { getHtmlToPdfUrl } = useGetApiUrls();
    const pdfref = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useGetMyApplication(studentID, setLoading);

    const export2PDF = () => {
        // const pdf = new jsPDF('p', 'mm', 'a4');
        const forms = pdfref.current?.querySelectorAll('.pdf-form-1');
        
        if (!forms) {
            console.error('Forms not found');
            return;
        }

        const divElement = document.getElementById(`divApplicationAccordion${studentID}`);
        divElement!.style.display = 'block';

        try {
            let ImagesToPdf: Array<string>;
            ImagesToPdf = [];
            let count = 0;
            Array.from(forms).forEach((form, index) => {
                html2canvas(form as HTMLDivElement, { scrollY: -window.scrollY, scale: 2 }).then((canvas) => {

                    const imageData = canvas.toDataURL('image/png');
                    ImagesToPdf.push(imageData);
                    count++;
                }).finally(() => {
                    if (count == 4) {
                        DownloadApplicationPdf(ImagesToPdf);
                    }
                });
            });
        } finally {
            divElement!.style.display = 'none';
        }
    };

    const DownloadApplicationPdf = (images64: any) => {
        let dataRequest: Html2Pdf = {
            programYear: data.programYear,
            images: images64
        }

        const fileName = `${session.displayName} - ${data.studentNumber}`;
        useGetHtmlToPdf(getHtmlToPdfUrl, session.apiToken ?? "", fileName, dataRequest, setIsLoading);
    }

    return <>
        <div>
        {<BackDrop open={isLoading} />}

            <div style={{ 'width': '600px' }} onClick={export2PDF}>
                <div id={`divApplicationAccordion${studentID}`} style={{ 'display': 'none' }}ref={pdfref} className="document-box">
                    {data && (
                        <>
                            <div>
                                <h3 className='pdf-text'>Online Application Form (Year: {data.programYear})</h3>
                                <ApplicationAccordion data={data}></ApplicationAccordion>
                            </div>
                        </>
                    )}
                </div>
            </div>

        </div>
    </>;
};

export default ExportPdf;