import axios, { AxiosRequestConfig } from 'axios';

const useHttpClient = (apitoken: any) => {
    const requestHandler = (request: any) => {
        if (apitoken) {
            request.headers.Authorization = `Bearer ${apitoken}`;
        }

        return request;
    };
    axios.interceptors.request.use((request) => requestHandler(request));

    return {
        post: axios.post,
        get: axios.get,
        put: axios.put,
        delete: axios.delete,
    };
};

export default useHttpClient;