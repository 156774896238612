import { SESSION_ESTABLISHED, ALERT_MESSAGE_ENQUEUED, SESSION_LOGOUT } from '../../Constants/Session';
import Session from '../../Entities/Session'
import { UserRole } from '../../Enums/Enums';

const SessionReducer = (state: Session, action: { type: string, payload: Session }) => {
    switch (action.type) {
        case SESSION_ESTABLISHED:
            return {
                ...state
                , userName: action.payload.userName
                , apiToken: action.payload.apiToken
                , userAgent: action.payload.userAgent
                , firstName: action.payload.firstName
                , lastName: action.payload.lastName
                , displayName: action.payload.displayName
                , icID: action.payload.icID
                , icName: action.payload.icName
                , emailAddress: action.payload.emailAddress
                , logoutServer: action.payload.logoutServer
                , iPAddress: action.payload.iPAddress
                , isAuthenticated: true
                , roleName: action.payload.roleName
                , token: action.payload.token
                , password: action.payload.password
                , isDevMode: action.payload.isDevMode
                , email: action.payload.email
                , apiUrl: action.payload.apiUrl
                , userID: action.payload.userID
                , roleID: action.payload.roleID
                , idleTimeOutInMins: action.payload.idleTimeOutInMins
                , isUserPI: action.payload.roleID == UserRole.Student
                , userRoleDisplayName: action.payload.userRoleDisplayName
                , studentID: action.payload.studentID
                , applicationVersion: action.payload.applicationVersion
                , applicationReleaseDate: action.payload.applicationReleaseDate
            }

        case ALERT_MESSAGE_ENQUEUED:
            return {
                ...state,
                alertMessage: action.payload.alertMessage
            }

        case SESSION_LOGOUT:
            return {
                ...state
                , isAuthenticated: false
                , apiToken: ''
                , apiUrl: ''
                , applicantID: 0
                , displayName: ''
                , emailAddress: ''
                , firstName: ''
                , hasApplicants: false
                , iPAddress: ''
                , lastName: ''
                , logoutServer: ''
                , applicationID: 0
                , userAgent: ''
            }

        default:
            return state;
    }


}

export default SessionReducer;