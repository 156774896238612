import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import dayjs from "dayjs";
import useGetApiUrls from '../../Common/Hooks/useGetApiUrls';
import useGetLookupMaster from '../../Common/Hooks/useGetLookupMaster';
import { LookupMasterType } from '../../Common/Enums/Enums';
import { useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import StudentPINameInfo from '../../Common/Entities/StudentPIName';
import StudentResearchInterestInfo from '../../Common/Entities/StudentResearchInterestInfo';

interface IApplicationAccordionProps {
    data: OnLineApplicationInfo;
}

const ApplicationAccordion: React.FunctionComponent<IApplicationAccordionProps> = ({ data }) => {
    const { getlookupMasterUrl } = useGetApiUrls();
    const lookupMaster = useGetLookupMaster(getlookupMasterUrl);

    // console.log(data.researchInterestInfo);
    // console.log(data.researchInterestInfo?.piNames);

    const anticipatedGraduationDate = data !== null && data.personalInformationInfo?.anticipatedGraduationDate !== null ? dayjs(data.personalInformationInfo?.anticipatedGraduationDate).format("MM/DD/YYYY") : '';

    const irtaAgree = data !== null && data.applicationStatus !== null && data.applicationStatus !== undefined ? data.applicationStatus >= 2 ? true : false : false;
    const applicateAgree = data !== null && data.applicationStatus !== null && data.applicationStatus !== undefined ? data.applicationStatus >= 2 ? true : false : false;

    const category1 = data !== null ? data.researchInterestInfo?.studentResearchInterestInfo?.filter(x => x.orderByPreference !== undefined && x.orderByPreference <= 2) : null;
    const category2 = data !== null ? data.researchInterestInfo?.studentResearchInterestInfo?.filter(x => x.orderByPreference !== undefined && x.orderByPreference >= 3 && x.orderByPreference <= 4) : null;
    const category3 = data !== null ? data.researchInterestInfo?.studentResearchInterestInfo?.filter(x => x.orderByPreference !== undefined && x.orderByPreference >= 5) : null;

    const [expanded, setExpanded] = useState<string | false>('panel1');

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    let salutation: string = '';
    const salutationList = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.Salutation && x.lookupMasterId === data.personalInformationInfo?.salutationID);
    if (salutationList.length > 0) {
        salutation = salutationList[0].lookupName ?? '';
    }

    let state: string = '';
    const stateList = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.State && x.lookupMasterId === data.personalInformationInfo?.schoolStateID);
    if (stateList.length > 0) {
        state = stateList[0].lookupName ?? '';
    }

    let country: string = '';
    const countryList = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.Citizenship && x.lookupMasterId === data.personalInformationInfo?.schoolCountryID);
    if (countryList.length > 0) {
        country = countryList[0].lookupName ?? '';
    }

    let estimateTimeline: string = '';
    const estimateTimelineList = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.Timeline && x.lookupMasterId === data.personalInformationInfo?.estimateTimelineID);
    if (estimateTimelineList.length > 0) {
        estimateTimeline = estimateTimelineList[0].lookupName ?? '';
    }

    let learnAboutInro: string = '';
    const learnAboutInroList = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.LearnAboutINRO && x.lookupMasterId === data.personalInformationInfo?.learnAboutInroID);
    if (learnAboutInroList.length > 0) {
        learnAboutInro = learnAboutInroList[0].lookupName ?? '';
    }

    let preferredNIAIDresearchLocation: string = '';
    const preferredNIAIDresearchLocationList = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.PreferredResearchLocation && x.lookupMasterId === data.personalInformationInfo?.preferredNIAIDresearchLocation);
    if (preferredNIAIDresearchLocationList.length > 0) {
        preferredNIAIDresearchLocation = preferredNIAIDresearchLocationList[0].lookupName ?? '';
    }

    const piNames = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.PIName);
    const piNameList = data && data.researchInterestInfo && data.researchInterestInfo.piNames && data.researchInterestInfo.piNames.map((item: StudentPINameInfo) => {
        const name = piNames.filter(x => x.lookupMasterId === item.piNameID);
        return (
            <li>
                {name.length > 0 ? name[0].lookupName : ''}
            </li>
        )
    });

    const researchInterestsCategory1 = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.ResearchInterestCategory1);
    const researchInterestsCategory1List = data && category1 && category1.map((item: StudentResearchInterestInfo) => {
        const name = researchInterestsCategory1.filter(x => x.lookupMasterId === item.researchInterestID);
        return (
            <li>
                {name.length > 0 ? name[0].lookupName : ''}
            </li>
        )
    });

    const researchInterestsCategory2 = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.ResearchInterestCategory2);
    const researchInterestsCategory2List = data && category2 && category2.map((item: StudentResearchInterestInfo) => {
        const name = researchInterestsCategory2.filter(x => x.lookupMasterId === item.researchInterestID);
        return (
            <li>
                {name.length > 0 ? name[0].lookupName : ''}
            </li>
        )
    });

    const researchInterestsCategory3 = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.ResearchInterestCategory3);
    const researchInterestsCategory3List = data && category3 && category3.map((item: StudentResearchInterestInfo) => {
        const name = researchInterestsCategory3.filter(x => x.lookupMasterId === item.researchInterestID);
        return (
            <li>
                {name.length > 0 ? name[0].lookupName : ''}
            </li>
        )
    });

    const hasPreviousInternship = data.personalInformationInfo?.hasPreviousInternship ? 'Yes' : 'No';

    return (
        <div>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <div className="pdf-form-1">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography><b>Personal Information</b></Typography>
                        <div className="horizontal-line"></div>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Typography >
                            <div className="accordion-data">
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <p className="title"><b>Prefix</b></p>
                                        <p className="description">{salutation ?? ''}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="title"><b>First Name</b></p>
                                        <p className="description">{data.personalInformationInfo?.firstName ?? ''}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="title"><b>Middle Name</b></p>
                                        <p className="description">{data.personalInformationInfo?.middleName ?? ''}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="title"><b>Last Name</b></p>
                                        <p className="description">{data.personalInformationInfo?.lastName ?? ''}</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <p className="title"><b>Primary Email</b></p>
                                        <p className="description">{data.personalInformationInfo?.primaryEmail ?? ''}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="title"><b>Secondary Email</b></p>
                                        <p className="description">{data.personalInformationInfo?.secondaryEmail ?? ''}</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-6">
                                        <p className="title"><b>Current School: (Enter Full School Name)</b></p>
                                        <p className="description">{data.personalInformationInfo?.schoolName ?? ''}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <p className="title"><b>Graduation Date (Anticipated)</b></p>
                                        <p className="description">{anticipatedGraduationDate}</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <p className="title"><b>Address at School</b></p>
                                        <p className="description">{data.personalInformationInfo?.schoolAddress1 ?? ''}</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-md-3">
                                        <p className="title"><b>City</b></p>
                                        <p className="description">{data.personalInformationInfo?.schoolCity ?? ''}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="title"><b>State</b></p>
                                        <p className="description">{state ?? ''}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="title"><b>Zip Code</b></p>
                                        <p className="description">{data.personalInformationInfo?.schoolZipCode ?? ''}</p>
                                    </div>
                                    <div className="col-md-3">
                                        <p className="title"><b>Country</b></p>
                                        <p className="description">{country ?? ''}</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <p className="title"><b>Please provide your timeline for applying to graduate/medical school:</b></p>
                                        <p className="description">{estimateTimeline ?? ''}</p>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col">
                                        <p className="title"><b>How did you first learn about INRO?</b></p>
                                        <p className="description">{learnAboutInro ?? ''}</p>
                                    </div>
                                </div>

                                {hasPreviousInternship === "Yes" ? (
                                    <>
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <p className="title"><b>Have you had any previous NIH internship or training?</b></p>
                                                <p className="description">{hasPreviousInternship}</p>
                                            </div>
                                            <div className="col-md-6">
                                                <p className="title"><b>{"If yes, please specify (ex. 2023 Summer Internship Program)"}</b></p>
                                                <p className="description">{data.personalInformationInfo?.trainingProgramsAttended}</p>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row mb-3">
                                            <div className="col-md-12">
                                                <p className="title"><b>Have you had any previous NIH internship or training?</b></p>
                                                <p className="description">{hasPreviousInternship}</p>
                                            </div>
                                        </div>
                                    </>
                                )}

                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <p className="title"><b>Preferred NIAID research location</b></p>
                                        <p className="description">{preferredNIAIDresearchLocation}</p>
                                    </div>
                                </div>

                            </div>
                        </Typography>

                    </AccordionDetails>
                </div>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <div className="pdf-form-1">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography><b>Diversity Statement</b></Typography>
                        <div className="horizontal-line"></div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div dangerouslySetInnerHTML={{ __html: data.diversityStatement ?? '' }} />
                        </Typography>
                    </AccordionDetails>
                </div>
            </Accordion>
            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <div className="pdf-form-1">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header"
                    >
                        <Typography><b>Research Interests</b></Typography>
                        <div className="horizontal-line"></div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <div className="row">
                                <div className="col-md-12">
                                    <b>Research interests/goals</b>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div dangerouslySetInnerHTML={{ __html: data.researchInterestInfo?.summaryOfRearchInterest ?? '' }} />
                                </div>
                            </div>
                            <div><br></br></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <b>NIAD PI</b>
                                </div>
                            </div>


                            <div className="row ml-2">
                                <ul>
                                    {data && data.researchInterestInfo && data.researchInterestInfo.piNames &&
                                        <>
                                            {piNameList}
                                        </>
                                    }
                                </ul>
                            </div>
                            <div><br></br></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <b>Additional Information</b>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    {data.researchInterestInfo?.additionalInformation}
                                </div>
                            </div>
                            <div><br></br></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <b>Categories</b>
                                </div>
                            </div>

                            <div className="row ml-2">
                                <div className="col-md-12">
                                    <b>Category 1</b>
                                </div>
                                <div className="row ml-2">
                                    <ul>
                                        {data && category1 &&
                                            <>
                                                {researchInterestsCategory1List}
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row ml-2">
                                <div className="col-md-12">
                                    <b>Category 2</b>
                                </div>
                                <div className="row ml-2">
                                    <ul>
                                        {data && category2 &&
                                            <>
                                                {researchInterestsCategory2List}
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="row ml-2">
                                <div className="col-md-12">
                                    <b>Category 3</b>
                                </div>
                                <div className="row ml-2">
                                    <ul>
                                        {data && category3 &&
                                            <>
                                                {researchInterestsCategory3List}
                                            </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </Typography>
                    </AccordionDetails>
                </div>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <div className="pdf-form-1">
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel4a-content"
                        id="panel4a-header"
                    >
                        <Typography><b>Student Submission Information</b></Typography>
                        <div className="horizontal-line"></div>
                    </AccordionSummary>
                    <AccordionDetails>

                        <Typography >
                            <div className="accordion-data">
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel control={<Checkbox checked={irtaAgree} name="checked2" />} className="mb-3" label={`I understand that if I am selected for INRO, I must confirm my laboratory by March 1 and plan to begin my Postbac IRTA Fellowship on June ${data.programYear}.`} />
                                                <FormControlLabel control={<Checkbox checked={applicateAgree} name="checked3" />} className="mb-3" label='I certify to the best of my knowledge and belief, all of the information in this application, attached to this application, and submitted subsequent to this application is true, correct, complete, and made in good faith. I understand that false or fraudulent information on or attached to this application may be grounds for disqualifying me from the program or rejecting my application after beign accepted into the program. I understand that any information I give may be investigated. I certify that I am a US citizen or US permanent resident.' />
                                            </FormGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                        </Typography>

                    </AccordionDetails>
                </div>
            </Accordion>

        </div>

    );

}
export default ApplicationAccordion;