import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import { useEffect, useState, useCallback, useContext, useRef } from 'react';
import httpClient from './useHttpClient';
import OtpUser from '../Entities/OtpUser';
import Session from '../Entities/Session';
import { useNavigate } from 'react-router-dom';

const useGetOTPSessionTest = (): [(otpUser: OtpUser) => void, boolean, boolean, boolean] => {
    const { session, setSession } = useContext(SessionContext);
    const [isLoading, setLoading] = useState(true);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isUnauthorized, setIsUnauthorized] = useState(false);
    let navigate = useNavigate();

    const isMounted = useRef(true);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const getOTPSessiotnTest = useCallback((otpUser: OtpUser) => {
        httpClient('')
            .post('/otp/renderOtpTest', otpUser, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                // check user has access
                if (c.data.apiSession.apiToken !== '') {
                    //console.log(c.data.apiSession);
                    //console.log(JSON.parse(c.data.apiSession) as Session);
                    setSession(JSON.parse(c.data.apiSession) as Session);

                    setAuthenticated(true);
                    setLoading(false);

                    navigate('/Dashboard', { replace: true });

                    return;
                }
                setAuthenticated(false);
                setLoading(false);
            })
            .catch((c) => {
                setAuthenticated(false);
                setLoading(false);
                setIsUnauthorized(false);
                })
                .finally(() => {
                    //make sending flag off if component is not unmounted
                    if (isMounted.current) {
                        setLoading(false);
                    }
            });
    }, [isLoading, isAuthenticated, isUnauthorized]);

    return [getOTPSessiotnTest, isLoading, isAuthenticated, isUnauthorized];

};

export default useGetOTPSessionTest;

/*
const useGetOTPSessionTest = (): [boolean, boolean, boolean] => {
    //const { session, setSession } = useContext(SessionContext);
    const [isAuthenticated, setAuthenticated] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [isUnauthorized, setIsUnauthorized] = useState(false);

    useEffect(() => {
        httpClient('')
            .post('/otp/renderOtpTest', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                // check user has access
                if (c.data?.apiToken) {
                    //setSession(c.data);

                    setAuthenticated(true);
                    setLoading(false);
                    return;
                }
                setAuthenticated(false);
                setLoading(false);
            })
            .catch((c) => {
                setAuthenticated(false);
                setLoading(false);
                setIsUnauthorized(false);
            });
    }, []);
    return [isAuthenticated, isLoading, isUnauthorized];
};

export default useGetOTPSessionTest;
*/