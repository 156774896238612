import { useEffect, useState } from 'react';
import httpClient from './useHttpClient';

const useGetIsDevMode = (): [boolean] => {
    const [devMode, setDevMode] = useState(false);

    useEffect(() => {
        httpClient('')
            .post('/api/data/IsDevMode', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                if (c.data !== null) {
                    setDevMode(c.data);
                    return;
                }
                setDevMode(false);
            })
            .catch((c) => {
                setDevMode(false);
            });
    }, []);
    return [devMode];
};

export default useGetIsDevMode;