import { useEffect, useState, useContext } from 'react';
import httpClient from '../../Common/Hooks/useHttpClient';
import InroInformationInfo from '../Entities/InroInformation';

const useGetInroInformation = (): [InroInformationInfo] => {
    const [inroInformationInfo, setInroInformationInfo] = useState<InroInformationInfo>({} as InroInformationInfo)

    useEffect(() => {
        httpClient('')
            .post('/api/data/GetInroInformation', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                if (c.data !== null) {
                    setInroInformationInfo(c.data);
                    return;
                }
                setInroInformationInfo({} as InroInformationInfo);
            })
            .catch((c) => {
                setInroInformationInfo({} as InroInformationInfo);
            });
        setInroInformationInfo({} as InroInformationInfo);
    }, []);
    return [inroInformationInfo];
};

export default useGetInroInformation;