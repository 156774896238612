export enum UserRole {
    Student = 0
}

export enum LookupMasterType {
    Citizenship = 1,
    Salutation = 2,
    State = 13,
    LearnAboutINRO = 16,
    PreferredResearchLocation = 30,
    ResearchInterestCategory1 = 31,
    ResearchInterestCategory2 = 1030,
    ResearchInterestCategory3 = 1031,
    Timeline = 1032,
    PIName = 1033
}

export enum PreviousInternshipType {
    None = 765,
    One = 250,
    Two = 251,
    Three = 252,
    Four = 253
}

export enum ApplicationStatus {
    Saved = 1,
    Submitted = 2,
    NotSet
}
