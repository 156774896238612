import * as React from 'react';
import { Button } from '@mui/material';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import { useNavigate } from 'react-router-dom';
import useSaveApplication from '../../Common/Hooks/useSaveApplication';
import InroInformationInfo from '../../Common/Entities/InroInformation';

interface IApplicationInformationHeaderProps {
    data: OnLineApplicationInfo;
    inroInformationInfo: InroInformationInfo;
    handleValidate?: any;
    export2PDF?: any;
}

const ApplicationInformationHeader: React.FunctionComponent<IApplicationInformationHeaderProps> = ({ data, inroInformationInfo, handleValidate, export2PDF }) => {
    let navigate = useNavigate();

    const dashboardClick = (e: any) => {
        e.preventDefault();
        navigate('/Dashboard', { replace: true });
    }

    const [saveApplication, isSending] = useSaveApplication();

    const handleSaveAll = () => {
        saveApplication(data, true);
    };

    const isSubmitted = inroInformationInfo !== undefined && inroInformationInfo !== null && inroInformationInfo.inroYear !== data.programYear ? true : data.applicationStatus !== null && data.applicationStatus !== undefined && data.applicationStatus >= 2;

    const applicationStatusCode = data.applicationStatusText !== 'Saved' ? 'Submitted' : 'Saved';

    return (
        <>
            {data && (

                <div className="application-info">
                    <a onClick={dashboardClick} className="btn btn-back-bdrs">Back to Applicant List</a>
                    <div className="d-flex align-items-center">
                        <span className="description">{data.personalInformationInfo?.firstName + ' ' + data.personalInformationInfo?.lastName}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="title">Application#:</span>
                        <span className="description">{data.studentNumber ?? ''}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="title">Application Status:</span>
                        <span className="description">{applicationStatusCode ?? ''}</span>
                    </div>
                    <div className="d-flex align-items-center">
                        <span className="title">Submission Date:</span>
                        <span className="description">{data.submissionDateText ?? ''}</span>
                    </div>
                    <div className="left-border-box">
                        {!isSubmitted && <Button size="medium" className="btn btn btn-save btn-lowercase ml-2" onClick={handleSaveAll}>Save All</Button>}
                        {!isSubmitted && <Button size="medium" className="btn btn-primary btn-lowercase ml-2" onClick={handleValidate}>Validate</Button>}
                        <Button size="medium" className="btn btn-primary btn-lowercase ml-2" onClick={export2PDF}>Export to PDF</Button>
                    </div>
                </div>
            )}
        </>
    );

}

export default ApplicationInformationHeader;