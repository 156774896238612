import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import RichTextField from '../../Common/Components/RichTextField';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import { LookupMasterType } from '../../Common/Enums/Enums';
import LookupMaster from '../../Common/Entities/LookupMaster';
import StudentPINameInfo from '../../Common/Entities/StudentPIName';
import StudentResearchInterestInfo from '../../Common/Entities/StudentResearchInterestInfo';
import ValidationError from '../../Common/Entities/ValidationError';

interface IResearchInterestsProps {
    data: OnLineApplicationInfo;
    setData: Dispatch<SetStateAction<OnLineApplicationInfo>>;
    validate: any;
    validationErrors: boolean;
    errors: ValidationError[];
    lookupMaster: LookupMaster[];
}

const ResearchInterests: React.FunctionComponent<IResearchInterestsProps> = ({ data, setData, validate, validationErrors, errors, lookupMaster }) => {

    const piNames = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.PIName);
    const researchInterestsCategory1 = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.ResearchInterestCategory1);
    const researchInterestsCategory2 = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.ResearchInterestCategory2);
    const researchInterestsCategory3 = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.ResearchInterestCategory3);

    let PIName1ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 1)[0];
    let PIName2ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 2)[0];
    let PIName3ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 3)[0];
    let PIName4ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 4)[0];

    const [PIname1, setPIname1] = useState(PIName1ID?.piNameID ?? 0);
    const [PIname2, setPIname2] = useState(PIName2ID?.piNameID ?? 0);
    const [PIname3, setPIname3] = useState(PIName3ID?.piNameID ?? 0);
    const [PIname4, setPIname4] = useState(PIName4ID?.piNameID ?? 0);

    const handlePInames1Change = (event: any) => {
        handlePInamesChange(1, Number(event.target.value));
        setPIname1(Number(event.target.value));
    };

    const handlePInames2Change = (event: any) => {
        handlePInamesChange(2, Number(event.target.value));
        setPIname2(Number(event.target.value));
    };

    const handlePInames3Change = (event: any) => {
        handlePInamesChange(3, Number(event.target.value));
        setPIname3(Number(event.target.value));
    };

    const handlePInames4Change = (event: any) => {
        handlePInamesChange(4, Number(event.target.value));
        setPIname4(Number(event.target.value));
    };

    const handlePInamesChange = (orderByPreference: number, value: number) => {
        let p = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === orderByPreference)[0];
        if (p !== null && p !== undefined) {
            p!.piNameID = value;
        } else {
            let piName: StudentPINameInfo = { piNameID: value, orderByPreference: orderByPreference };
            data.researchInterestInfo!.piNames?.push(piName);
        }

        setData({ ...data });
    };

    const aditionalinformationChange = (event: any) => {
        data.researchInterestInfo!.additionalInformation = event.target.value;
        setData({ ...data });
    }

    let cat1ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 1)[0];
    let cat2ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 2)[0];
    let cat3ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 3)[0];
    let cat4ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 4)[0];
    let cat5ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 5)[0];
    let cat6ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 6)[0];

    const [cat1, setCat1] = useState(cat1ID?.researchInterestID ?? 0);
    const [cat2, setCat2] = useState(cat2ID?.researchInterestID ?? 0);
    const [cat3, setCat3] = useState(cat3ID?.researchInterestID ?? 0);
    const [cat4, setCat4] = useState(cat4ID?.researchInterestID ?? 0);
    const [cat5, setCat5] = useState(cat5ID?.researchInterestID ?? 0);
    const [cat6, setCat6] = useState(cat6ID?.researchInterestID ?? 0);

    const handleCat1Change = (event: any) => {
        handleCatChange(1, Number(event.target.value));
        setCat1(Number(event.target.value));
    };

    const handleCat2Change = (event: any) => {
        handleCatChange(2, Number(event.target.value));
        setCat2(Number(event.target.value));
    };

    const handleCat3Change = (event: any) => {
        handleCatChange(3, Number(event.target.value));
        setCat3(Number(event.target.value));
    };

    const handleCat4Change = (event: any) => {
        handleCatChange(4, Number(event.target.value));
        setCat4(Number(event.target.value));
    };

    const handleCat5Change = (event: any) => {
        handleCatChange(5, Number(event.target.value));
        setCat5(Number(event.target.value));
    };

    const handleCat6Change = (event: any) => {
        handleCatChange(6, Number(event.target.value));
        setCat6(Number(event.target.value));
    };

    const handleCatChange = (orderByPreference: number, value: number) => {
        let r = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === orderByPreference)[0];

        if (r !== null && r !== undefined) {
            r!.researchInterestID = value;
        } else {
            let cat: StudentResearchInterestInfo = { researchInterestID: value, orderByPreference: orderByPreference };
            data.researchInterestInfo!.studentResearchInterestInfo?.push(cat);
        }

        setData({ ...data });
    };

    const properCaseCat2 = researchInterestsCategory2.length > 0 &&
        researchInterestsCategory2.map(option => {
            option.lookupName = option.lookupName?.toLowerCase().replace(/^\w|\s\w/g, letter => letter.toUpperCase());
        }, this);

    const properCaseCat3 = researchInterestsCategory3.length > 0 &&
        researchInterestsCategory3.map(option => {
            option.lookupName = option.lookupName?.toLowerCase().replace(/^\w|\s\w/g, letter => letter.toUpperCase());
        }, this);


    return (
        <>
            <div>
                <div className="gray-box mb-0">
                    <p>This section provides you with opportunity to explicitly state your research interests and goals. Please remember, this is an NIAID program and, if selected, we will work to identify NIAID labs/mentors that are a good fit for your research interests and career goals. <b>This section should be used to emphasize what you would hope to research in NIAID if selected for the progam.</b></p>
                    <span>Please provide the following:</span><br></br>
                    <span><b>2-3 sentences on your research interests/goals. <span className="MuiFormLabel-asterisk">*</span></b></span><br></br>
                    <span><b>2-3 sentences on your relevant research experiences. <span className="MuiFormLabel-asterisk">*</span></b></span>
                </div>
                <div className="mb-5 mt-5">
                    <RichTextField id={'summaryOfRearchInterest'} value={data.researchInterestInfo?.summaryOfRearchInterest} data={data} setData={setData} validate={validate} />
                    {validationErrors && errors[14].message && (
                        <FormHelperText className="text-danger">
                            {errors[14].message}
                        </FormHelperText>
                    )}
                </div>

                <div className="box-border-top mb-5">
                    <div className="row">
                        <div className="col-md-6">
                            <p>Using the following dropdown list, select up to 4 NIAID PI's whose research you are interested in; if there are any other NIAID investigators that you would like to list, you may do so in the text box below.</p>
                            <p>For more information about research conducted in NIAID labs, you can use the Intramural Research Program (IRP) website to <a href="https://irp.nih.gov/our-research/principal-investigators/focus" target="_blank">search by Focus Area</a> OR the complete list of NIH investigators <a href="https://www.niaid.nih.gov/research/research-conducted-niaid" target="_blank">Click here</a> (you should be identifying NIAID investigators only).</p>
                            <p><span><b>NOTE:</b></span><br></br>
                                Not all NIAID PI's have expressed position availability and/or the ability to mentor a student through the INRO program this year - The NIAID training office (ORTD) will assist with identifying labs for INRO finalists. Your selections here are used to help the NIAID training office identify PI's and laboratories for interviews should you be invited to the INRO event in February. <span className="MuiFormLabel-asterisk">*</span>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <form>

                                <h6>NIAID PI:<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></h6>
                                <FormControl>
                                    <InputLabel id="PInames1-select-label" shrink={true}>PI Name 1</InputLabel>
                                    <Select
                                        labelId="PInames1-select-label"
                                        id="PInames1-select"
                                        className="mb-3"
                                        value={(PIname1 ?? 0) === 0 ? '' : PIname1}
                                        onChange={handlePInames1Change}
                                        onBlur={validate}
                                    >
                                        <MenuItem key={0} value={3269}></MenuItem>
                                        {piNames.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[18].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[18].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="PInames2-select-label" shrink={true}>PI Name 2</InputLabel>
                                    <Select
                                        labelId="PInames2-select-label"
                                        id="PInames2-select"
                                        className="mb-3"
                                        value={(PIname2 ?? 0) === 0 ? '' : PIname2}
                                        onChange={handlePInames2Change}
                                        onBlur={validate}
                                    >
                                        <MenuItem key={0} value={0}></MenuItem>
                                        {piNames.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[20].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[20].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="PInames3-select-label" shrink={true}>PI Name 3</InputLabel>
                                    <Select
                                        labelId="PInames3-select-label"
                                        id="PInames3-select"
                                        className="mb-3"
                                        value={(PIname3 ?? 0) === 0 ? '' : PIname3}
                                        onChange={handlePInames3Change}
                                        onBlur={validate}
                                    >
                                        <MenuItem key={0} value={0}></MenuItem>
                                        {piNames.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[21].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[21].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="PInames4-select-label" shrink={true}>PI Name 4</InputLabel>
                                    <Select
                                        labelId="PInames4-select-label"
                                        id="PInames4-select"
                                        className="mb-3"
                                        value={(PIname4 ?? 0) === 0 ? '' : PIname4}
                                        onChange={handlePInames4Change}
                                        onBlur={validate}
                                    >
                                        <MenuItem key={0} value={0}></MenuItem>
                                        {piNames.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[22].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[22].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="aditional-information-label" shrink={true}>Additional Information</InputLabel>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={4}
                                        value={data.researchInterestInfo?.additionalInformation ?? ''}
                                        onChange={aditionalinformationChange}
                                        variant="outlined"
                                    />
                                </FormControl>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="box-border-top mt-3">
                    <p>Choose 3 - 6 Research topics. You must select at least 1 from each category.</p>
                    <form>
                        <p className='footer-version'>Category 1</p>
                        <div className="row">
                            <div className="col-md-6">
                                <FormControl>
                                    <InputLabel id="ResearchInterest1-select-label" shrink={true}>Research Interest 1<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                                    <Select
                                        labelId="ResearchInterest1-select-label"
                                        id="ResearchInterest1-select"
                                        className="mb-3"
                                        required
                                        onBlur={validate}
                                        value={cat1 === 0 ? '' : cat1}
                                        onChange={handleCat1Change}
                                    >
                                        {researchInterestsCategory1.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[15].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[15].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl>
                                    <InputLabel id="ResearchInterest2-select-label" shrink={true}>Research Interest 2</InputLabel>
                                    <Select
                                        labelId="ResearchInterest2-select-label"
                                        id="ResearchInterest2-select"
                                        className="mb-3"
                                        value={cat2 === 0 ? '' : cat2}
                                        onChange={handleCat2Change}
                                        onBlur={validate}
                                    >
                                        {researchInterestsCategory1.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[23].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[23].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                        <p className='footer-version'>Category 2</p>
                        <div className="row">
                            <div className="col-md-6">
                                <FormControl>
                                    <InputLabel id="ResearchInterest3-select-label" shrink={true}>Research Interest 1<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                                    <Select
                                        labelId="ResearchInterest3-select-label"
                                        id="ResearchInterest3-select"
                                        className="mb-3"
                                        required
                                        value={cat3 === 0 ? '' : cat3}
                                        onChange={handleCat3Change}
                                        onBlur={validate}
                                    >
                                        {researchInterestsCategory2.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[16].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[16].message}
                                        </FormHelperText>
                                    )}
                                    {validationErrors && errors[24].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[24].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl>
                                    <InputLabel id="ResearchInterest4-select-label" shrink={true}>Research Interest 2</InputLabel>
                                    <Select
                                        labelId="ResearchInterest4-select-label"
                                        id="ResearchInterest4-select"
                                        className="mb-3"
                                        value={cat4 === 0 ? '' : cat4}
                                        onChange={handleCat4Change}
                                        onBlur={validate}
                                    >
                                        {researchInterestsCategory2.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[25].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[25].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                        <p className='footer-version'>Category 3</p>
                        <div className="row">
                            <div className="col-md-6">
                                <FormControl>
                                    <InputLabel id="ResearchInterest5-select-label" shrink={true}>Research Interest 1<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                                    <Select
                                        labelId="ResearchInterest5-select-label"
                                        id="ResearchInterest5-select"
                                        className="mb-3"
                                        required
                                        value={cat5 === 0 ? '' : cat5}
                                        onChange={handleCat5Change}
                                        onBlur={validate}
                                    >
                                        {researchInterestsCategory3.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[17].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[17].message}
                                        </FormHelperText>
                                    )}
                                    {validationErrors && errors[26].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[26].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                            <div className="col-md-6">
                                <FormControl>
                                    <InputLabel id="ResearchInterest6-select-label" shrink={true}>Research Interest 2</InputLabel>
                                    <Select
                                        labelId="ResearchInterest6-select-label"
                                        id="ResearchInterest6-select"
                                        className="mb-3"
                                        value={cat6 === 0 ? '' : cat6}
                                        onChange={handleCat6Change}
                                        onBlur={validate}
                                    >
                                        {researchInterestsCategory3.map(option => (
                                            <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                        ))}
                                    </Select>
                                    {validationErrors && errors[27].message && (
                                        <FormHelperText className="text-danger">
                                            {errors[27].message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default ResearchInterests;