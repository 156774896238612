import * as React from 'react';
import { Button } from '@mui/material';

interface ISideBarProps {
    isView: boolean;
}

const SideBar: React.FunctionComponent<ISideBarProps> = ({ isView }) => {
    var url = "#"

    return (
        <div className="sidebar-right">
            {/* {isView && (
                <>
                    <div className="d-flex justify-content-between align-items-center">
                        <h5>Attachments</h5>
                        <Button size="medium" className="btn btn-upload btn-lowercase ml-2">Upload Attachments</Button>
                    </div>
                    <div className="gray-box d-flex justify-content-between align-items-center">
                        <span><i>Fowler_Amy_OIT_Doc.pdf</i></span>
                        <div className="d-flex justify-content-end">
                            <a href={url} className="btn btn-download-icon"> </a>
                            <a href={url} className="btn btn-delete-icon"> </a>
                        </div>
                    </div>
                </>
            )} */}
            <h5>Please review the following links</h5>
            <div className="gray-box">
                <p>Before you begin the application, please review the following links</p>
                <ul>
                    <li><a href="https://www.niaid.nih.gov/about/inro" target="_blank">INRO Web Site</a></li>
                    <li><a href="https://www2.training.nih.gov/apps/publicForms/pbt/forms/login.aspx" target="_blank">OITE Application Portal</a></li>
                    <li><a href="https://www.training.nih.gov/programs/postbac_irta" target="_blank">OITE Postbac Page</a></li>
                    <li><a href="https://www.niaid.nih.gov/privacy" target="_blank">Privacy Statement</a></li>
                </ul>
            </div>
            {!isView && (
                <>
                    <h5>Instructions for using and saving this form.</h5>
                    <div className="gray-box">
                        <p>This application is optimized for Edge and Chrome. If you have any questions while completing the form, <b>SAVE</b> your application and email <a href="mailto: NIAIDTraining@nih.gov">NIAIDTraining@nih.gov </a> for assistance</p>
                        <h6>SAVE</h6>
                        <p>If you are unable to finish the application in one sitting, press the [Save All] button on the top right. Using your email address and password, you will be able to log back in and complete your application.</p>
                        <p>Please note, your application is not valid until you submit it.</p>
                        <h6>SUBMIT</h6>
                        <p>After completing your application, press [Submit]. You will receive a confirmation email and you will no longer be able to modify your application.  If you need to make additional changes, you can contact <a href="mailto: NIAIDTraining@nih.gov">NIAIDTraining@nih.gov</a>.</p>
                    </div>
                </>
            )}
        </div>
    );

}

export default SideBar;