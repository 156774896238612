import { useEffect, useState } from 'react';
import httpClient from './useHttpClient';

const useGetOtpSiteUrl = (): [string] => {
    const [otpSiteUrl, setOtpSiteUrl] = useState('');

    useEffect(() => {
        httpClient('')
            .post('/api/data/GetOtpSiteUrl', {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then((c) => {
                if (c.data !== null) {
                    setOtpSiteUrl(c.data);
                    return;
                }
                setOtpSiteUrl('');
            })
            .catch((c) => {
                setOtpSiteUrl('');
            });
    }, []);
    return [otpSiteUrl];
};

export default useGetOtpSiteUrl;