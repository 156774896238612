import * as React from 'react';
import OnLineApplicationInfo from '../Entities/OnLineApplicationInfo';
import { Dispatch, SetStateAction } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

interface IRichTextFieldProps {
    id: string;
    value?: string;
    data: OnLineApplicationInfo;
    setData: Dispatch<SetStateAction<OnLineApplicationInfo>>;
    validate: any;
}

const RichTextField: React.FunctionComponent<IRichTextFieldProps> = ({ id, value, data, setData, validate }) => {

    const textChange = (txt: any) => {
        if (id === 'diversityStatement') {
            data.diversityStatement = txt;
        } else if (id = 'summaryOfRearchInterest') {
            data.researchInterestInfo!.summaryOfRearchInterest = txt;
        }

        setData({ ...data });

        validate();
    }

    return (
        <>
            <div className="App">
                <CKEditor
                    editor={ClassicEditor}
                    config={ {
                        toolbar: [ 'bold', 'italic', 'numberedList', 'bulletedList', '-', 'outdent', 'indent', '-', 'blockquote' ]
                    } }
                    data={value ?? ''}
                    onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                        //console.log('Editor is ready to use!', editor);
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        textChange(data);
                        //console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        const data = editor.getData();
                        textChange(data);
                       //console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        //console.log('Focus.', editor);
                    }}
                />

            </div>
        </>
    );
};

export default RichTextField;