import * as React from 'react';
import { Button, Stepper, Step, StepLabel, Typography } from '@material-ui/core';
import { useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from '../../Common/Contexts/SessionContext/SessionContext';
import DiversityStatement from './DiversityStatement';
import ResearchInterests from './ResearchInterests';
import ApplicationValidation from './ApplicationValidation';
import ApplicationSubmission from './ApplicationSubmission';
import PersonalInformation from './PersonalInformation';
import useGetMyApplication from '../../Common/Hooks/useGetMyApplication';
import { ApplicationStatus } from '../../Common/Enums/Enums';
import useSaveApplication from '../../Common/Hooks/useSaveApplication';
import useGetApiUrls from '../../Common/Hooks/useGetApiUrls';
import useGetLookupMaster from '../../Common/Hooks/useGetLookupMaster';
import ValidationError from '../../Common/Entities/ValidationError';
import { APPLICATION_SUBMISSION, APPLICATION_VALIDATION, DIVERSITY_STATEMENT, PERSONAL_INFORMATION, RESEARCH_INTERESTS } from '../../Common/Constants/ApplicationConstant';
import useGetInroInformation from '../../Common/Hooks/useGetInroInformation';
import SideBar from './SideBar';
import ApplicationInformationHeader from './ApplicationInformationHeader';
import useGetSchoolNames from '../../Common/Hooks/useGetSchoolNames';
import useGetHtmlToPdf from '../../Common/Hooks/useGetHtmlToPdf';
import Html2Pdf from '../../Common/Entities/Html2Pdf';
import html2canvas from 'html2canvas';
import ApplicationAccordion from './ApplicationAccordion';
import BackDrop from '../../Common/Components/BackDrop';

interface IOnlineApplicationFormProps {
    handleDownloadPDF?: any;
}

const OnlineApplicationForm: React.FunctionComponent<IOnlineApplicationFormProps> = ({ handleDownloadPDF }) => {
    const { session, setAlertMessage } = useContext(SessionContext);
    const [loading, setLoading] = useState(false);
    const { getlookupMasterUrl, getSchoolNamesUrl } = useGetApiUrls();
    const lookupMaster = useGetLookupMaster(getlookupMasterUrl, true);
    const schoolNames = useGetSchoolNames(getSchoolNamesUrl);

    const [data, setData] = useGetMyApplication(session.studentID ?? 0, setLoading);
    const [inroInformationInfo] = useGetInroInformation();

    const [saveApplication, isSending] = useSaveApplication();

    const [validationErrorList, setValidationErrorList] = useState([] as string[]);
    const [validationErrors, setValidationErrors] = useState(false);

    const errorObj: ValidationError[] = [
        { section: PERSONAL_INFORMATION, label: 'First Name', sectionOrder: 0, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Last Name', sectionOrder: 1, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Primary Email', sectionOrder: 2, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Name of school', sectionOrder: 3, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Graduation Date (Anticipated)', sectionOrder: 4, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Address at school', sectionOrder: 5, message: '' },
        { section: PERSONAL_INFORMATION, label: 'City', sectionOrder: 6, message: '' },
        { section: PERSONAL_INFORMATION, label: 'State', sectionOrder: 7, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Zip code', sectionOrder: 8, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Country', sectionOrder: 9, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Learn about INRO', sectionOrder: 10, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Previous internship', sectionOrder: 11, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Preferred research location', sectionOrder: 12, message: '' },
        { section: DIVERSITY_STATEMENT, label: 'Diversity statement', sectionOrder: 13, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Research interests and goals', sectionOrder: 14, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Research topics category 1', sectionOrder: 15, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Research topics category 2', sectionOrder: 16, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Research topics category 3', sectionOrder: 17, message: '' },
        { section: RESEARCH_INTERESTS, label: 'PI Name', sectionOrder: 18, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Secondary email cannot match the primary email', sectionOrder: 19, message: '' },
        { section: RESEARCH_INTERESTS, label: 'PI Name 2 is a duplicate', sectionOrder: 20, message: '' },
        { section: RESEARCH_INTERESTS, label: 'PI Name 3 is a duplicate', sectionOrder: 21, message: '' },
        { section: RESEARCH_INTERESTS, label: 'PI Name 4 is a duplicate', sectionOrder: 22, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Category 1, Reasearch Interest 2 is a duplicate', sectionOrder: 23, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Category 2, Reasearch Interest 1 is a duplicate', sectionOrder: 24, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Category 2, Reasearch Interest 2 is a duplicate', sectionOrder: 25, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Category 3, Reasearch Interest 1 is a duplicate', sectionOrder: 26, message: '' },
        { section: RESEARCH_INTERESTS, label: 'Category 3, Reasearch Interest 2 is a duplicate', sectionOrder: 27, message: '' },
        { section: PERSONAL_INFORMATION, label: 'Previous internship description', sectionOrder: 28, message: '' },
    ];

    const [errors, setErrors] = useState<ValidationError[]>(errorObj);

    const formValid = ({ ...errors }) => {
        let isValid = true;

        setValidationErrorList([]);
        var valErrors: string[] = [];

        Object.values(errors).map(x => {
            if (x.message !== '') {
                isValid = false;
                valErrors.push(x.message);
            }
        });

        if (valErrors.length > 0) {
            setValidationErrorList(valErrors);
        }

        return isValid;
    }

    const validate = (): boolean => {
        Object.values(errors).map(x => {
            x.message = '';
        });

        let isValid = true;

        if (data.personalInformationInfo!.firstName === '' || data.personalInformationInfo!.firstName === null) {
            errors[0].message = `${errors[0].label} is required`;
        }

        if (data.personalInformationInfo!.lastName === '' || data.personalInformationInfo!.lastName === null) {
            errors[1].message = `${errors[1].label} is required`;
        }

        if (data.personalInformationInfo!.primaryEmail === '' || data.personalInformationInfo!.primaryEmail === null) {
            errors[2].message = `${errors[2].label} is required`;
        }

        if (data.personalInformationInfo!.primaryEmail !== '' && data.personalInformationInfo!.secondaryEmail !== '') {
            if (data.personalInformationInfo!.primaryEmail?.toLowerCase().trim() === data.personalInformationInfo!.secondaryEmail?.toLowerCase().trim()) {
                errors[19].message = 'Secondary email cannot match the primary email';
            }
        }

        if (data.personalInformationInfo!.schoolName === '' || data.personalInformationInfo!.schoolName === null) {
            errors[3].message = `${errors[3].label} is required`;
        }

        if (data.personalInformationInfo!.anticipatedGraduationDate === null || data.personalInformationInfo!.anticipatedGraduationDate === undefined || data.personalInformationInfo!.anticipatedGraduationDate.toString() === 'Invalid Date') {
            errors[4].message = `${errors[4].label} is required`;
        }

        if (data.personalInformationInfo!.schoolAddress1 === '' || data.personalInformationInfo!.schoolAddress1 === null) {
            errors[5].message = `${errors[5].label} is required`;
        }

        if (data.personalInformationInfo!.schoolCity === '' || data.personalInformationInfo!.schoolCity === null) {
            errors[6].message = `${errors[6].label} is required`;
        }

        if (data.personalInformationInfo!.schoolStateID === 0 || data.personalInformationInfo!.schoolStateID === null) {
            errors[7].message = `${errors[7].label} is required`;
        }

        if (data.personalInformationInfo!.schoolZipCode === '' || data.personalInformationInfo!.schoolZipCode === null) {
            errors[8].message = `${errors[8].label} is required`;
        }

        if (data.personalInformationInfo!.schoolCountryID === 0 || data.personalInformationInfo!.schoolCountryID === null) {
            errors[9].message = `${errors[9].label} is required`;
        }

        if (data.personalInformationInfo!.learnAboutInroID === 0 || data.personalInformationInfo!.learnAboutInroID === null) {
            errors[10].message = `${errors[10].label} is required`;
        }

        // if (data.personalInformationInfo!.hasPreviousInternship === '' || data.personalInformationInfo!.hasPreviousInternship === null) {
        //    errors[11].message = `${errors[1].label} is required`;
        // }

        if (data.personalInformationInfo!.hasPreviousInternship !== null) {
            if (data.personalInformationInfo!.hasPreviousInternship) {
                if (data.personalInformationInfo!.trainingProgramsAttended === '' || data.personalInformationInfo!.trainingProgramsAttended === null) {
                    errors[28].message = `${errors[28].label} is required`;
                }
            }
        }

        if (data.personalInformationInfo!.preferredNIAIDresearchLocation === 0 || data.personalInformationInfo!.preferredNIAIDresearchLocation === null) {
            errors[12].message = `${errors[12].label} is required`;
        }

        if (data.diversityStatement === '' || data.diversityStatement === null) {
            errors[13].message = `${errors[13].label} is required`;
        }

        if (data.researchInterestInfo!.summaryOfRearchInterest === '' || data.researchInterestInfo!.summaryOfRearchInterest === null) {
            errors[14].message = `${errors[14].label} is required`;
        }

        let cat1ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 1)[0];
        let cat2ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 2)[0];
        let cat3ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 3)[0];
        let cat4ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 4)[0];
        let cat5ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 5)[0];
        let cat6ID = data.researchInterestInfo!.studentResearchInterestInfo?.filter(x => x.orderByPreference === 6)[0];

        if (cat1ID === undefined) {
            errors[15].message = `${errors[15].label} is required`;
        }

        if (cat3ID === undefined) {
            errors[16].message = `${errors[16].label} is required`;
        }

        if (cat5ID === undefined) {
            errors[17].message = `${errors[17].label} is required`;
        }

        if (cat1ID !== undefined && cat2ID !== undefined) {
            if (cat1ID.researchInterestID === cat2ID.researchInterestID) {
                errors[23].message = 'Category 1, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat3ID !== undefined && cat4ID !== undefined) {
            if (cat3ID.researchInterestID === cat4ID.researchInterestID) {
                errors[25].message = 'Category 2, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat5ID !== undefined && cat6ID !== undefined) {
            if (cat5ID.researchInterestID === cat6ID.researchInterestID) {
                errors[27].message = 'Category 3, Reasearch Interest 2 is a duplicate';
            }
        }

        let rcat1 = '';
        let rcat2 = '';
        let rcat3 = '';
        let rcat4 = '';
        let rcat5 = '';
        let rcat6 = '';

        if (cat1ID !== undefined) {
            const c1 = lookupMaster.filter(x => x.lookupMasterId === cat1ID?.researchInterestID);

            if (c1.length > 0) {
                rcat1 = c1[0].lookupName!.toUpperCase();
            }
        }

        if (cat2ID !== undefined) {
            const c2 = lookupMaster.filter(x => x.lookupMasterId === cat2ID?.researchInterestID);

            if (c2.length > 0) {
                rcat2 = c2[0].lookupName!.toUpperCase();
            }
        }

        if (cat3ID !== undefined) {
            const c3 = lookupMaster.filter(x => x.lookupMasterId === cat3ID?.researchInterestID);

            if (c3.length > 0) {
                rcat3 = c3[0].lookupName!.toUpperCase();
            }
        }

        if (cat4ID !== undefined) {
            const c4 = lookupMaster.filter(x => x.lookupMasterId === cat4ID?.researchInterestID);

            if (c4.length > 0) {
                rcat4 = c4[0].lookupName!.toUpperCase();
            }
        }

        if (cat5ID !== undefined) {
            const c5 = lookupMaster.filter(x => x.lookupMasterId === cat5ID?.researchInterestID);

            if (c5.length > 0) {
                rcat5 = c5[0].lookupName!.toUpperCase();
            }
        }

        if (cat6ID !== undefined) {
            const c6 = lookupMaster.filter(x => x.lookupMasterId === cat6ID?.researchInterestID);

            if (c6.length > 0) {
                rcat6 = c6[0].lookupName!.toUpperCase();
            }
        }

        if (cat1ID !== undefined && cat3ID !== undefined) {
            if (rcat1 === rcat3) {
                errors[24].message = 'Category 2, Reasearch Interest 1 is a duplicate';
            }
        }

        if (cat1ID !== undefined && cat4ID !== undefined) {
            if (rcat1 === rcat4) {
                errors[25].message = 'Category 2, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat1ID !== undefined && cat5ID !== undefined) {
            if (rcat1 === rcat5) {
                errors[26].message = 'Category 3, Reasearch Interest 1 is a duplicate';
            }
        }

        if (cat1ID !== undefined && cat6ID !== undefined) {
            if (rcat1 === rcat6) {
                errors[27].message = 'Category 3, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat2ID !== undefined && cat3ID !== undefined) {
            if (rcat2 === rcat3) {
                errors[24].message = 'Category 2, Reasearch Interest 1 is a duplicate';
            }
        }

        if (cat2ID !== undefined && cat4ID !== undefined) {
            if (rcat2 === rcat4) {
                errors[25].message = 'Category 2, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat2ID !== undefined && cat5ID !== undefined) {
            if (rcat2 === rcat5) {
                errors[26].message = 'Category 3, Reasearch Interest 1 is a duplicate';
            }
        }

        if (cat2ID !== undefined && cat6ID !== undefined) {
            if (rcat2 === rcat6) {
                errors[27].message = 'Category 3, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat3ID !== undefined && cat5ID !== undefined) {
            if (rcat3 === rcat5) {
                errors[26].message = 'Category 3, Reasearch Interest 1 is a duplicate';
            }
        }

        if (cat3ID !== undefined && cat6ID !== undefined) {
            if (rcat3 === rcat6) {
                errors[27].message = 'Category 3, Reasearch Interest 2 is a duplicate';
            }
        }

        if (cat4ID !== undefined && cat5ID !== undefined) {
            if (rcat4 === rcat5) {
                errors[26].message = 'Category 3, Reasearch Interest 1 is a duplicate';
            }
        }

        if (cat4ID !== undefined && cat6ID !== undefined) {
            if (rcat4 === rcat6) {
                errors[27].message = 'Category 3, Reasearch Interest 2 is a duplicate';
            }
        }

        let PIName1ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 1)[0];

        if (PIName1ID === undefined) {
            errors[18].message = `${errors[18].label} is required`;
        }

        let PIName2ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 2)[0];
        let PIName3ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 3)[0];
        let PIName4ID = data.researchInterestInfo!.piNames?.filter(x => x.orderByPreference === 4)[0];

        if (PIName1ID !== undefined && PIName2ID !== undefined) {
            if (PIName1ID.piNameID === PIName2ID.piNameID && PIName2ID.piNameID !== undefined && PIName2ID.piNameID > 0) {
                errors[20].message = 'PI Name 2 is a duplicate';
            }
        }

        if (PIName1ID !== undefined && PIName3ID !== undefined) {
            if (PIName1ID.piNameID === PIName3ID.piNameID && PIName3ID.piNameID !== undefined && PIName3ID.piNameID > 0) {
                errors[21].message = 'PI Name 3 is a duplicate';
            }
        }

        if (PIName1ID !== undefined && PIName4ID !== undefined) {
            if (PIName1ID.piNameID === PIName4ID.piNameID && PIName4ID.piNameID !== undefined && PIName4ID.piNameID > 0) {
                errors[22].message = 'PI Name 4 is a duplicate';
            }
        }

        if (PIName2ID !== undefined && PIName3ID !== undefined) {
            if (PIName2ID.piNameID === PIName3ID.piNameID && PIName3ID.piNameID !== undefined && PIName3ID.piNameID > 0) {
                errors[21].message = 'PI Name 3 is a duplicate';
            }
        }

        if (PIName2ID !== undefined && PIName4ID !== undefined) {
            if (PIName2ID.piNameID === PIName4ID.piNameID && PIName4ID.piNameID !== undefined && PIName4ID.piNameID > 0) {
                errors[22].message = 'PI Name 4 is a duplicate';
            }
        }

        if (PIName3ID !== undefined && PIName4ID !== undefined) {
            if (PIName3ID.piNameID === PIName4ID.piNameID && PIName4ID.piNameID !== undefined && PIName4ID.piNameID > 0) {
                errors[22].message = 'PI Name 4 is a duplicate';
            }
        }

        setErrors({ ...errors });

        isValid = formValid(errors);
        setValidationErrors(!isValid);

        return !isValid;
    };

    // Data for progress stepper
    const stepInfo = [
        { label: 'Personal Information', icon: PERSONAL_INFORMATION, id: '1' },
        { label: 'Diversity Statement', icon: DIVERSITY_STATEMENT, id: '2' },
        { label: 'Research Interests', icon: RESEARCH_INTERESTS, id: '3' },
        { label: 'Application Validation', icon: APPLICATION_VALIDATION, id: '4' },
        { label: 'Application Submission', icon: APPLICATION_SUBMISSION, id: '5' },
    ];

    const getSteps = () => {
        return stepInfo;
    };

    // Content change for each step
    const getStepContent = (step: any) => {
        switch (step) {
            case 0:
                return <PersonalInformation data={data} setData={setData} validate={validate} validationErrors={validationErrors} errors={errors} lookupMaster={lookupMaster} schoolNames={schoolNames} />;
            case 1:
                return <DiversityStatement data={data} setData={setData} validationErrors={validationErrors} errors={errors} validate={validate} />;
            case 2:
                return <ResearchInterests data={data} setData={setData} validate={validate} validationErrors={validationErrors} errors={errors} lookupMaster={lookupMaster} />;
            case 3:
                return <ApplicationValidation data={data} errors={errors} />;
            case 4:
                return <ApplicationSubmission data={data} setData={setData} validationErrors={validationErrors} errors={errors} />;
            default:
                return 'Unknown step';
        }
    }

    // parsing className for icons
    const parseName = (icon: any) => {
        icon = icon.replace(/\s/g, '');
        icon = icon.toLowerCase();

        return icon;
    }

    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    const scrollTop = () => {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        scrollTop();
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        scrollTop();
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleSaveAndGoBack = () => {
        saveApplication(data, true);
        handleBack();
    };

    const handleSave = () => {
        saveApplication(data, true);
    };

    const handleSaveAndGoNext = () => {
        saveApplication(data, true);
        handleNext();
    };

    const handleSubmit = () => {
        //validate
        let hasValidationErrors = false;

        hasValidationErrors = validate();
        setValidationErrors(hasValidationErrors);

        if (hasValidationErrors) {
            setAlertMessage({ ...session, alertMessage: { type: 'warning', message: 'Application validation needs attention' } });

            setActiveStep(3);
        } else {
            data.applicationStatus = ApplicationStatus.Submitted;

            saveApplication(data, true);
        }
    };

    const handleValidate = () => {
        //validate
        let hasValidationErrors = false;

        hasValidationErrors = validate();
        setValidationErrors(hasValidationErrors);

        if (hasValidationErrors) {
            setAlertMessage({ ...session, alertMessage: { type: 'warning', message: 'Application validation needs attention' } });

            setActiveStep(3);
        } else {
            setAlertMessage({ ...session, alertMessage: { type: 'info', message: 'Application validated without errors' } });

            setActiveStep(4);
        }
    };

    useEffect(() => {
        if (data !== undefined && data !== null && data.personalInformationInfo !== null && data.personalInformationInfo !== undefined) {
            let hasValidationErrors = false;

            hasValidationErrors = validate();
            setValidationErrors(hasValidationErrors);
        }
    }, [activeStep])

    let button_prev;
    if (activeStep === 3 || activeStep === 4) {
        button_prev = <Button size="medium" onClick={handleBack} className="btn btn-back">Previous</Button>;
    } else if (activeStep > 0) {
        button_prev = <Button size="medium" onClick={handleSaveAndGoBack} className="btn btn-back">Save and Go Back</Button>;
    } else {
        button_prev = <Button size="medium" onClick={handleSave} className="btn btn-save">Save</Button>;
    }

    let button_next;

    if (activeStep === 4) {
        button_next = <Button size="medium" onClick={handleSubmit} className="btn btn-primary" disabled={!(data.irtaAgree && data.applicateAgree)}>Submit</Button>;
    } else if (activeStep == 3) {
        button_next = <Button size="medium" onClick={handleNext} className="btn btn-next">Next</Button>;
    } else {
        button_next = <Button size="medium" onClick={handleSaveAndGoNext} className="btn btn-next">Save and Go Next</Button>;
    }

    const { getHtmlToPdfUrl } = useGetApiUrls();

    const [isLoading, setIsLoading] = useState(false);
    const pdfref = useRef<HTMLDivElement>(null);
    const export2PDF = () => {
        // const pdf = new jsPDF('p', 'mm', 'a4');
        const forms = pdfref.current?.querySelectorAll('.pdf-form-1');

        if (!forms) {
            console.error('Forms not found');
            return;
        }

        const divElement = document.getElementById('divApplicationAccordion');
        divElement!.style.display = 'block';

        try {
            let ImagesToPdf: Array<string>;
            ImagesToPdf = [];
            let count = 0;
            Array.from(forms).forEach((form, index) => {
                html2canvas(form as HTMLDivElement, { scrollY: -window.scrollY, scale: 2 }).then((canvas) => {

                    const imageData = canvas.toDataURL('image/png');
                    ImagesToPdf.push(imageData);
                    count++;
                }).finally(() => {
                    if (count == 4) {
                        DownloadApplicationPdf(ImagesToPdf);
                    }
                });
            });
        } finally {
            divElement!.style.display = 'none';
        }
    };

    const DownloadApplicationPdf = (images64: any) => {
        let dataRequest: Html2Pdf = {
            programYear: data.programYear,
            images: images64
        }

        const fileName = `${session.displayName} - ${data.studentNumber}`;
        useGetHtmlToPdf(getHtmlToPdfUrl, session.apiToken ?? "", fileName, dataRequest, setIsLoading);
    }



    return (
        <>
            <ApplicationInformationHeader data={data} inroInformationInfo={inroInformationInfo} handleValidate={handleValidate} export2PDF={export2PDF}></ApplicationInformationHeader>
       
            {<BackDrop open={isLoading} />}

            <div className="main-content-wrapper">
                <div className="document-box">
                    {inroInformationInfo && (
                        <h3>Online Application Form (Year: {inroInformationInfo.inroYear})</h3>
                    )}

                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((step, index) => (
                            <Step key={index} className={index === activeStep ? 'active' : ''} onClick={() => setActiveStep(index)}>
                                <StepLabel key={step.id} StepIconComponent={() => <span className={`step-icon step-${parseName(step.icon)}`} key={`sub-${index}`}></span>}>{step.label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography >
                                    All steps completed - you&apos;re finished
                                </Typography>
                                <Button onClick={handleReset} >
                                    Reset
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Typography >
                                    {getStepContent(activeStep)}
                                </Typography>
                                <div className="d-flex justify-content-between mt-5">
                                    {button_prev}
                                    {button_next}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <SideBar isView={false}></SideBar>
                <div id="divApplicationAccordion" style={{ 'display': 'none' }} ref={pdfref} className="document-box">
                    {inroInformationInfo && (
                        <>
                            <div>
                                <h3 className='pdf-text'>Online Application Form (Year: {inroInformationInfo.inroYear})</h3>
                                <ApplicationAccordion data={data}></ApplicationAccordion>
                            </div>
                        </>
                    )}
                </div>

            </div>
        </>
    );
};

export default OnlineApplicationForm;