import * as React from 'react';
import { Backdrop, Button, Fade, Modal } from '@mui/material';
import { useContext, useState } from 'react';
import httpClient from '../../Common/Hooks/useHttpClient';
import useGetApiUrls from '../../Common/Hooks/useGetApiUrls';
import { SessionContext } from '../../Common/Contexts/SessionContext/SessionContext';
import { useNavigate } from 'react-router-dom';

interface IPIAgreementProps {
    className: string,
    buttonText: string,
    size: "small" | "medium" | "large",
    studentID: number
}

const PIAgreement: React.FunctionComponent<IPIAgreementProps> = ({ className, buttonText, size, studentID }) => {
    const { session } = useContext(SessionContext);
    const [isSending, setIsSending] = useState(false);
    const { getUpdatePIIAgreementUrl } = useGetApiUrls();
    let navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }
    };

    const saveClick = (event: any) => {
        event.preventDefault();

        setIsSending(true);
        httpClient(session.apiToken)
            .post(getUpdatePIIAgreementUrl, studentID, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(() => {
                navigate('/Application', { replace: true });

                handleClose();
            })
            .catch((error: any) => {
                console.log(error);
            })
            .finally(() => {
                setIsSending(false);
            });
    }

    const handleBackdropClick = (event: any) => {
        //these fail to keep the modal open
        event.stopPropagation();
        return false;
    };

    const onOverlayClick = (e: any) => {
        e.stopPropagation();
    };

    return <>
        <div onClick={onOverlayClick}>
            <Button size={size} className={className} onClick={handleOpen} variant="contained" color="info">
                {buttonText}
            </Button>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={`modal-custom`}
                open={open}
                onClose={handleClose}
                onBackdropClick={handleBackdropClick}
                disableEscapeKeyDown
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div>
                        <div className={`paper-custom`} style={{ 'width': '600px' }}>

                            <div className="d-flex justify-content-between">
                                <h2>PII Agreement</h2>
                                <Button size="small" className="btn btn-close" onClick={handleClose}></Button>
                            </div>

                            <div className="modal-main-content">
                                <div className="modal-section-marker">
                                    <hr></hr>
                                </div>

                                <div className="row ml-2">
                                    The following application will require that you enter certain Personally Identifiable Information (PII).
                                    <div className="mt-3">
                                        Click OK to continue to the application.
                                    </div>
                                </div>
                                <div className="btns-right mt-4">
                                    <Button size="medium" className="btn btn-primary" disabled={isSending} onClick={saveClick}>OK</Button>
                                </div>


                            </div>

                        </div>


                    </div>
                </Fade>



            </Modal>
        </div>
    </>;
};

export default PIAgreement;