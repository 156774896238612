import { useCallback, useContext } from 'react';
import httpClient from './useHttpClient';
import { useNavigate } from 'react-router-dom';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import { EXTERNAL_ID_TOKEN, EXTERNAL_CURRENT_USER, EXT_TOKEN_CREATION, EXTERNAL_ID_TOKEN_HASH } from '../Constants/OtpConstant';

const useLogout = (): () => void => {
    const navigate = useNavigate();

    const { session, setSessionLogout } = useContext(SessionContext);

    const onLogout = useCallback(() => {
        const idToken = localStorage.getItem(EXTERNAL_ID_TOKEN);

        localStorage.removeItem(EXTERNAL_ID_TOKEN);
        localStorage.removeItem(EXT_TOKEN_CREATION);
        localStorage.removeItem(EXTERNAL_CURRENT_USER);
        localStorage.removeItem(EXTERNAL_ID_TOKEN_HASH);

        let body = { accTk: '', idToken: idToken, refreshToken: '', reserved: '' };

        httpClient('')
            .post('/api/otp/logoutOtp', body)
            .then(c => {
                setSessionLogout(session);

                window.location = c.data;
            })
            .catch((c) => { })
    }, [])

    return onLogout;

};

export default useLogout;