import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import ApplicationInfo from '../Entities/ApplicationInfo';

interface IDataTableProps {
    rows: ApplicationInfo[];
    columns: GridColDef[];
    loading?: boolean;
}

const DataTable: React.FunctionComponent<IDataTableProps> = ({ rows, columns, loading }) => {

    return (
        <DataGrid
            rows={rows}
            columns={columns}
            density="comfortable"
            autoHeight={true}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
        />
    );

}

export default DataTable;