import * as React from 'react';
import VersionInformationInfo from '../Entities/VersionInformationInfo';

import hhsImg from './../../Static/Images/nih-branding/logo-hhs-gray.svg';
import nihMark from './../../Static/Images/nih-branding/logo-nih-mark-gray.svg';
import usaGov from './../../Static/Images/nih-branding/logo-usagov-gray.svg';

interface IFooterProps {
    versionInformationInfo: VersionInformationInfo;
}

const Footer: React.FunctionComponent<IFooterProps> = ({ versionInformationInfo }) => {
    let versionDate = versionInformationInfo && versionInformationInfo.versionDate ? new Date(versionInformationInfo.versionDate).toLocaleDateString() : '';

    return (
        <>
            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xs col-md-12">
                            <div className="footer-logos">
                                <img src={hhsImg} alt="Logo HHS" className="logo-footer-hhs" />
                                <img src={nihMark} alt="Logo NIH" className="logo-footer-nih" />
                                <img
                                    src={usaGov}
                                    alt="Logo HHS"
                                    className="logo-footer-usagov"
                                />
                            </div>
                            <div className="footer-text">
                                <div className="footer-content">
                                    <div className="req-links">
                                        <a
                                            href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html"
                                            //alt="visit NIAID.NIH.gov"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Vulnerability Disclosure Policy
                                        </a>
                                        <a
                                            href="https://www.niaid.nih.gov"
                                            //alt="visit NIAID.NIH.gov"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            NIAID.NIH.GOV
                                        </a>
                                        <a
                                            href="https://www.niaid.nih.gov/node/5228"
                                            //alt="view the No Fear Act"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            No Fear Act
                                        </a>
                                        <a
                                            href="https://www.niaid.nih.gov/node/5229"
                                            //alt="view the Privacy Policy"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Privacy Policy
                                        </a>
                                        <a
                                            href="https://www.niaid.nih.gov/node/5225"
                                            //alt="view the Freedom of Information Act"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            FOIA
                                        </a>
                                        <a
                                            href="https://inside.niaid.nih.gov/communications-media/accessibility-and-reasonable-accommodations"
                                            //alt="view Accessibility Guidelines"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Accessibility
                                        </a>
                                        <a
                                            href="mailto:INROSupport@niaid.nih.gov"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Technical support
                                        </a>
                                    </div>
                                </div>
                                <div className="application-details">
                                    <div className="footer-title">
                                        Intramural NIAID Research Oportunities (INRO)
                                    </div>
                                    <div className="footer-version">
                                        Version: {versionInformationInfo.versionNumber} | Last Release: {versionDate}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
};

export default Footer;