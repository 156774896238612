import { createContext, useReducer, useRef } from 'react';
import { ALERT_MESSAGE_ENQUEUED, SESSION_ESTABLISHED, SESSION_LOGOUT } from '../../Constants/Session';
import Session from '../../Entities/Session';
import SessionReducer from './SessionReducer';
import {
    browserName,
    fullBrowserVersion,
    deviceType,
    osName,
    osVersion
} from 'react-device-detect';

const initalSessionState = {
    session: {
        browser: browserName,
        browserId: 0,
        browser_version: fullBrowserVersion,
        device: deviceType,
        os: osName,
        os_version: osVersion,
        userAgent: '',
        studentID: 0,
        isShowEditModal: false,
        idleTimeOutInMins: 60,
        isAuthenticated: false
    } as Session,
    setSession: (session: Session) => { },
    setAlertMessage: (session: Session) => { },
    setSessionLogout: (session: Session) => { },
};

// create session Context object with initial state
export const SessionContext = createContext(initalSessionState);

type Props = {
    children: JSX.Element,
};

//create global session provider
export const SessionProvider: React.FC<Props> = ({ children }) => {

    const [state, dispatch] = useReducer(SessionReducer, initalSessionState.session)

    const setSession = (session: Session) => {
        dispatch(
            {
                type: SESSION_ESTABLISHED,
                payload: session
            }
        )
    };

    const setAlertMessage = (session: Session) => {
        dispatch(
            {
                type: ALERT_MESSAGE_ENQUEUED,
                payload: session
            }
        )
    };

    const setSessionLogout = (session: Session) => {
        dispatch(
            {
                type: SESSION_LOGOUT,
                payload: session
            }
        )
    };

    return <SessionContext.Provider
        value={{
            session: state
            , setSession
            , setAlertMessage
            , setSessionLogout
        }}> {children} </SessionContext.Provider>
}