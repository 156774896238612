import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from '../../Common/Contexts/SessionContext/SessionContext';
import useGetMyApplications from '../../Common/Hooks/useGetMyApplications';
import DataTable from '../../Common/Components/DataTable';
import { GridColDef } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import ApplicationInfo from '../../Common/Entities/ApplicationInfo';
import { Button } from '@mui/material';
import useCopyApplication from '../../Common/Hooks/useCopyApplication';
import PIAgreement from './PIAgreement';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import ExportPdf from '../../Application/Components/ExportPdf';

interface IDashboardProps {
}

const Dashboard: React.FunctionComponent<IDashboardProps> = (props) => {
    const { session } = useContext(SessionContext);
    const [rows, loading] = useGetMyApplications(session.userName ?? '');

    const [copyApplication, isSending] = useCopyApplication();

    let navigate = useNavigate();

    const editClick = (e: any, row: ApplicationInfo) => {
        e.preventDefault();
        session.studentID = row.studentID;
        navigate('/Application', { replace: true });
    }

    const viewApplication = (e: any, row: ApplicationInfo) => {
        e.preventDefault();
        session.studentID = row.studentID;
        navigate('/ApplicationView', { replace: true });
    }

    let [data, setData] = useState<OnLineApplicationInfo>({} as OnLineApplicationInfo);

    const copyLatestClick = (e: any) => {
        copyApplication(rows[0].studentID ?? 0, true);

        e.preventDefault();
    }

    const export2PDF = (e: any, row: ApplicationInfo) => {
        const divElement = document.getElementById(`divApplicationAccordion${row.studentID}`);

        if (divElement !== undefined && divElement !== null) {
            console.log(divElement);
            divElement.click();
        }
    }

    const columns: GridColDef[] = [
        { field: 'studentNumber', headerName: 'Application #', width: 10, flex: 2 },
        { field: 'programYear', headerName: 'INRO Year', width: 8, flex: 2 },
        { field: 'programStatus', headerName: 'INRO Status', width: 10, flex: 2 },
        { field: 'applicationStatusCode', headerName: 'Application Status', width: 12, flex: 2, valueFormatter: (params: any) => { return params.value !== 'Saved' ? 'Submitted' : 'Saved'; } },
        {
            field: "studentID",
            headerName: "",
            headerAlign: 'center',
            align: 'left',
            width: 300,
            sortable: false,
            renderCell: (params: any) => {
                return (
                    <>
                        <div>
                            {params.row.programStatus === 'Current' && params.row.applicationStatusCode == 'Saved' && params.row.isPIIAgreement && (
                                <Button size="medium" className="btn btn-primary" onClick={(e) => { editClick(e, params.row); }}>Edit</Button>
                            )}
                            {params.row.programStatus === 'Current' && params.row.applicationStatusCode == 'Saved' && !params.row.isPIIAgreement && (
                                <PIAgreement size="medium" className="btn btn-primary" buttonText="Edit" studentID={params.row.studentID} />
                            )}
                            {(params.row.programStatus === 'Expired' || params.row.applicationStatus >= 2) && (
                                <>
                                    <Button size="medium" className="btn btn-success" onClick={(e) => { viewApplication(e, params.row); }}>View application</Button>
                                    <Button size="medium" className="btn btn-primary btn-lowercase" onClick={(e) => { export2PDF(e, params.row); }}>Export to PDF</Button>
                                </>
                            )}
                        </div>
                    </>
                );
            },
        }
    ];

    return (
        <>
            <div className="jumbotron jumbotron-header">
                <div className="header-wrapper">
                    <div className="application-info elegibilityform-chevron">
                        <div className="box-with-chevron">
                            <div className="d-flex align-items-center mr-5">
                                <span className="title">My Applications</span>
                            </div>
                        </div>

                        <span className="title">{session.displayName}</span>
                    </div>
                </div>
            </div>

            <div className="main-content">
                <div className="container-fluid">
                    <div className="box-border-top">

                        {rows && rows.length > 0 && (
                            <>

                                {rows[0].programStatus === 'Current' && rows[0].applicationStatusCode == 'Saved' && (
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="row ml-2">
                                            <p>Your INRO application has been created, to edit and submit, please click the "Edit" button below. Once the application status is changed to "Submitted" you will no longer be able to edit.</p>

                                            <p>If you have any questions during the process of completing and submitting your application contact <a href="mailto:inro@niaid.nih.gov">INRO@niaid.nih.gov</a></p>
                                        </div>
                                    </div>
                                )}

                                {rows.length > 1 && rows[0].programStatus === 'Current' && rows[0].applicationStatusCode == 'Saved' && (
                                    <div>
                                        <Button size="medium" className="btn btn-primary" onClick={copyLatestClick}>Copy Data From Latest Application</Button>
                                    </div>
                                )}

                                <div className='mt-2' style={{ width: '60vw' }}>
                                    <DataTable rows={rows} columns={columns} loading={loading} />
                                </div>
                            </>
                        )}
                    </div>



                </div>
            </div>

            <div>
                {rows && rows.length > 0 && (
                    rows.map((x, index: number) => {

                        return (
                            <ExportPdf key={x.studentID} studentID={x.studentID ?? 0} />
                        )
                    })
                )}
            </div>
        </>
    );
};

export default Dashboard;