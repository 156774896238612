import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import ValidationError from '../../Common/Entities/ValidationError';

interface IApplicationSubmissionProps {
    data: OnLineApplicationInfo;
    setData: Dispatch<SetStateAction<OnLineApplicationInfo>>
    validationErrors: boolean;
    errors: ValidationError[];
}

const ApplicationSubmission: React.FunctionComponent<IApplicationSubmissionProps> = ({ data, setData, validationErrors, errors }) => {

    return (
        <>
            <div className="gray-box listed-cards-wrap">
            <div className="row">
                        <div className="col-md-6">
                            <p className="mb-1"><b>Name</b></p>
                            <p className="mb-0">{data.personalInformationInfo?.firstName + ' ' + data.personalInformationInfo?.lastName}</p>
                        </div>
                        <div className="col-md-6">
                            <p className="mb-1"><b>Email</b></p>
                            <p className="mb-0">{data.personalInformationInfo?.primaryEmail}</p>
                        </div>
                    </div>
            </div>

            <div className="box-border-top">
                <p><b>You may not submit this form unless you certified the following statements.</b></p>
                <FormControl component="fieldset">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox color="primary" checked={data.irtaAgree} onChange={(e) => setData({ ...data, irtaAgree: e.target.checked })} name="checked2" />} className="mb-3" label={`I understand that if I am selected for INRO, I must confirm my laboratory by March 1 and plan to begin my Postbac IRTA Fellowship on June ${data.programYear}.`} />
                        <FormControlLabel control={<Checkbox color="primary" checked={data.applicateAgree} onChange={(e) => setData({ ...data, applicateAgree: e.target.checked })} name="checked3" />} className="mb-3" label='I certify to the best of my knowledge and belief, all of the information in this application, attached to this application, and submitted subsequent to this application is true, correct, complete, and made in good faith. I understand that false or fraudulent information on or attached to this application may be grounds for disqualifying me from the program or rejecting my application after beign accepted into the program. I understand that any information I give may be investigated. I certify that I am a US citizen or US permanent resident.' />
                    </FormGroup>
                </FormControl>

                <p><span className="warning-icon"></span><b>WARNING:</b> Once you have submitted your application, you may not make any further changes. If you would like to verify any email addresses, coursework, etc. please verify the information in each section, and then SAVE your application.</p>
            </div>
        </>
    );
};

export default ApplicationSubmission;