import * as React from 'react';
import { Alert, AlertProps, Snackbar } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';

interface IToastProps { }

const Toast: React.FunctionComponent<IToastProps> = () => {
    const { setAlertMessage, session: { alertMessage }, session } = useContext(SessionContext);

    return <>

        {alertMessage && (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={!!alertMessage}
                autoHideDuration={3000}
                onClose={() => setAlertMessage({ ...session, alertMessage: null })}
            >
                <Alert severity={alertMessage.type} onClose={() => setAlertMessage({ ...session, alertMessage: null })}>{alertMessage.message}</Alert>
            </Snackbar>
        )}
    </>;
};

export default Toast;