import { Autocomplete, FormControl, FormControlLabel, FormHelperText, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import * as React from 'react';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import OnLineApplicationInfo from '../../Common/Entities/OnLineApplicationInfo';
import { LookupMasterType } from '../../Common/Enums/Enums';
import dayjs from "dayjs";
import LookupMaster from '../../Common/Entities/LookupMaster';
import ValidationError from '../../Common/Entities/ValidationError';

interface IPersonalInformationProps {
    data: OnLineApplicationInfo;
    setData: Dispatch<SetStateAction<OnLineApplicationInfo>>;
    validate: any;
    validationErrors: boolean;
    errors: ValidationError[];
    lookupMaster: LookupMaster[];
    schoolNames: LookupMaster[];
}

const PersonalInformation: React.FunctionComponent<IPersonalInformationProps> = ({ data, setData, validate, validationErrors, errors, lookupMaster, schoolNames }) => {

    const salutationChange = (event: any) => {
        data.personalInformationInfo!.salutationID = Number(event.target.value);
        setData({ ...data });
    }

    const firstNameChange = (event: any) => {
        data.personalInformationInfo!.firstName = event.target.value;
        setData({ ...data });
    }

    const lastNameChange = (event: any) => {
        data.personalInformationInfo!.lastName = event.target.value;
        setData({ ...data });
    }

    const middleNameChange = (event: any) => {
        data.personalInformationInfo!.middleName = event.target.value;
        setData({ ...data });
    }

    const primaryEmailChange = (event: any) => {
        data.personalInformationInfo!.primaryEmail = event.target.value;
        setData({ ...data });
    }

    const secondaryEmailChange = (event: any) => {
        data.personalInformationInfo!.secondaryEmail = event.target.value;
        setData({ ...data });
    }

    const schoolNameChange = (event: any) => {
        data.personalInformationInfo!.schoolName = event.target.outerText;
        setData({ ...data });
    }

    const schoolNameBlur = (event: any) => {
        data.personalInformationInfo!.schoolName = event.target.value;
        setData({ ...data });
        validate();
    }

    const anticipatedGraduationDateChange = (event: any) => {
        if (event !== null) {
            data.personalInformationInfo!.anticipatedGraduationDate = dayjs(event.target.value).toDate()
        } else {
            data.personalInformationInfo!.anticipatedGraduationDate = new Date('');
        }
        setData({ ...data });
    }

    const schoolAddress1Change = (event: any) => {
        data.personalInformationInfo!.schoolAddress1 = event.target.value;
        setData({ ...data });
    }

    const schoolCityChange = (event: any) => {
        data.personalInformationInfo!.schoolCity = event.target.value;
        setData({ ...data });
    }

    const schoolZipCodeChange = (event: any) => {
        data.personalInformationInfo!.schoolZipCode = event.target.value;
        setData({ ...data });
    }

    const stateChange = (event: any) => {
        let value = Number(event.target.value);

        data.personalInformationInfo!.schoolCountryID = value === 626 ? 0 : 226;
        data.personalInformationInfo!.schoolStateID = value;

        setData({ ...data });
    }

    const schoolCountryChange = (event: any) => {
        let value = Number(event.target.value);

        data.personalInformationInfo!.schoolCountryID = value;
        data.personalInformationInfo!.schoolStateID = value === 226 ? 0 : 626;

        setData({ ...data });
    }

    const estimateTimelineChange = (event: any) => {
        data.personalInformationInfo!.estimateTimelineID = Number(event.target.value);
        setData({ ...data });
    }

    const learnAboutInroChange = (event: any) => {
        data.personalInformationInfo!.learnAboutInroID = Number(event.target.value);
        setData({ ...data });
    }

    const researchLocationChange = (event: any) => {
        data.personalInformationInfo!.preferredNIAIDresearchLocation = Number(event.target.value);
        setData({ ...data });
    }

    const internshipTrainingChange = (event: boolean) => {
        data.personalInformationInfo!.hasPreviousInternship = event;
        setData({ ...data });
    }

    const trainingProgramsAttendedChange = (event: any) => {
        data.personalInformationInfo!.trainingProgramsAttended = event.target.value;
        setData({ ...data });
    }

    const salutation = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.Salutation);

    const usStates = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.State);

    const countryUSA = lookupMaster.filter(x => x.lookupMasterId === 226);
    const countries = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.Citizenship && x.lookupMasterId > 2 && x.lookupMasterId !== 226 && x.lookupMasterId !== 33 && x.lookupMasterId !== 227 && x.lookupMasterId !== 234);

    const schoolTimelineOptions = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.Timeline);

    const firstINROOptions = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.LearnAboutINRO);

    const preferredOptions = lookupMaster.filter(x => x.lookupMasterType == LookupMasterType.PreferredResearchLocation);
    preferredOptions.sort((a, b) => a.lookupMasterId.toString().localeCompare(b.lookupMasterId.toString()));

    return (
        <>
            <form>
                <div className="row mt-3">
                    <div className="col-md-3">
                        <FormControl>
                            <InputLabel id="prefix" className="select prefix" shrink={true}>Prefix</InputLabel>
                            <Select
                                labelId="prefix-label"
                                id="prefix"
                                className="select"
                                value={data.personalInformationInfo?.salutationID ?? ''}
                                onChange={salutationChange}
                            >
                                <MenuItem key={0} value={0}></MenuItem>
                                {salutation.map(option => (
                                    <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="First Name"
                                fullWidth
                                value={data.personalInformationInfo?.firstName ?? ''}
                                inputProps={{ maxLength: 50 }}
                                onChange={firstNameChange}
                                onBlur={validate}
                                required
                                error={validationErrors && errors[0].message !== ''}
                                helperText={validationErrors && errors[0].message !== '' ? errors[0].message : ''}

                            />
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Middle Name"
                                fullWidth
                                value={data.personalInformationInfo?.middleName ?? ''}
                                inputProps={{ maxLength: 50 }}
                                onChange={middleNameChange}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Last Name"
                                fullWidth
                                value={data.personalInformationInfo?.lastName ?? ''}
                                inputProps={{ maxLength: 50 }}
                                onChange={lastNameChange}
                                required
                                onBlur={validate}
                                error={validationErrors && errors[1].message !== ''}
                                helperText={validationErrors && errors[1].message !== '' ? errors[1].message : ''}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Primary Email"
                                fullWidth
                                value={data.personalInformationInfo?.primaryEmail ?? ''}
                                inputProps={{ maxLength: 100 }}
                                onChange={primaryEmailChange}
                                required
                                onBlur={validate}
                                error={validationErrors && errors[2].message !== ''}
                                helperText={validationErrors && errors[2].message !== '' ? errors[2].message : ''}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-6">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Secondary Email"
                                fullWidth
                                value={data.personalInformationInfo?.secondaryEmail ?? ''}
                                inputProps={{ maxLength: 100 }}
                                onChange={secondaryEmailChange}
                                onBlur={validate}
                                error={validationErrors && errors[19].message !== ''}
                                helperText={validationErrors && errors[19].message !== '' ? errors[19].message : ''}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-6">
                        <FormControl>
                            <InputLabel id="state-select-label" shrink={true}>Current School<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                            <Autocomplete
                                id="personalInformationInfoSchoolNames"
                                freeSolo
                                size="small"
                                value={data.personalInformationInfo?.schoolName ?? ''}
                                onBlur={schoolNameBlur}
                                onChange={schoolNameChange}
                                options={schoolNames.map((option) => option.lookupName)}
                                renderInput={(params) => <TextField {...params} variant="standard" size="small" />}
                            />
                            {validationErrors && errors[3].message && (
                                <FormHelperText className="text-danger">
                                    {errors[3].message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div className="col-md-3">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Graduation Date (Anticipated)"
                                type="date"
                                value={dayjs(data.personalInformationInfo?.anticipatedGraduationDate).format("YYYY-MM-DD")}
                                onChange={anticipatedGraduationDateChange}
                                onBlur={validate}
                                required
                                error={validationErrors && errors[4].message !== ''}
                                helperText={validationErrors && errors[4].message !== '' ? errors[4].message : ''}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Address at School"
                                fullWidth
                                value={data.personalInformationInfo?.schoolAddress1 ?? ''}
                                inputProps={{ maxLength: 100 }}
                                onChange={schoolAddress1Change}
                                onBlur={validate}
                                required
                                error={validationErrors && errors[5].message !== ''}
                                helperText={validationErrors && errors[5].message !== '' ? errors[5].message : ''}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col-md-3">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="City"
                                fullWidth
                                value={data.personalInformationInfo?.schoolCity ?? ''}
                                inputProps={{ maxLength: 100 }}
                                onChange={schoolCityChange}
                                onBlur={validate}
                                required
                                error={validationErrors && errors[6].message !== ''}
                                helperText={validationErrors && errors[6].message !== '' ? errors[6].message : ''}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl>
                            <InputLabel id="state-select-label" shrink={true}>State<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                            <Select
                                labelId="state-select-label"
                                id="state-select"
                                className="pt-0"
                                value={data.personalInformationInfo?.schoolStateID ?? ''}
                                onChange={stateChange}
                                required
                                onBlur={validate}
                                error={validationErrors && errors[7].message !== ''}
                            >
                                {usStates.map(option => (
                                    <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                ))}
                            </Select>
                            {validationErrors && errors[7].message && (
                                <FormHelperText className="text-danger">
                                    {errors[7].message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                    <div className="col-md-2">
                        <FormControl>
                            <TextField
                                InputLabelProps={{ shrink: true }}
                                label="Zip Code"
                                fullWidth
                                value={data.personalInformationInfo?.schoolZipCode ?? ''}
                                inputProps={{ maxLength: 9 }}
                                onChange={schoolZipCodeChange}
                                onBlur={validate}
                                required
                                error={validationErrors && errors[8].message !== ''}
                                helperText={validationErrors && errors[8].message !== '' ? errors[8].message : ''}
                            />
                        </FormControl>
                    </div>
                    <div className="col-md-5">
                        <FormControl>
                            <InputLabel id="country" shrink={true}>Country<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                            <Select
                                labelId="country-label"
                                id="country"
                                className="select"
                                value={data.personalInformationInfo?.schoolCountryID ?? ''}
                                onChange={schoolCountryChange}
                                onBlur={validate}
                                required
                                error={validationErrors && errors[9].message !== ''}

                            >
                                {countryUSA.map(option => (
                                    <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                ))}
                                {countries.map(option => (
                                    <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                ))}
                            </Select>
                            {validationErrors && errors[9].message && (
                                <FormHelperText className="text-danger">
                                    {errors[9].message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <FormControl>
                            <InputLabel id="timeline-select-label" shrink={true}>Provide your estimated timeline for applying to graduate/medical school:</InputLabel>
                            <Select
                                labelId="timeline-select-label"
                                id="timeline-select"
                                value={data.personalInformationInfo?.estimateTimelineID ?? ''}
                                onChange={estimateTimelineChange}
                            >
                                {schoolTimelineOptions.map(option => (
                                    <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col">
                        <FormControl>
                            <InputLabel id="firstINRO-select-label" shrink={true}>How did you first learn about INRO?<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></InputLabel>
                            <Select
                                labelId="firstINRO-select-label"
                                id="firstINRO-select"
                                value={data.personalInformationInfo?.learnAboutInroID ?? ''}
                                onChange={learnAboutInroChange}
                                required
                                onBlur={validate}
                                error={validationErrors && errors[10].message !== ''}
                            >
                                {firstINROOptions.map(option => (
                                    <MenuItem key={option.lookupMasterId} value={option.lookupMasterId}>{option.lookupName}</MenuItem>
                                ))}
                            </Select>
                            {validationErrors && errors[10].message && (
                                <FormHelperText className="text-danger">
                                    {errors[10].message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-5 mb-5">
                    <div className="toggle-button">
                        <p className="toggle-button-label">Have you had any previous NIH internships or trainings?<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></p>
                        <div className="toggle-button-container">
                            <div className={`toggle-button-option ${data.personalInformationInfo?.hasPreviousInternship ? 'selected' : ''}`} onClick={() => internshipTrainingChange(true)}>
                                <div className="toggle-button-option__label">Yes</div>
                            </div>
                            <div className={`toggle-button-option ${!data.personalInformationInfo?.hasPreviousInternship ? 'selected' : ''}`} onClick={() => internshipTrainingChange(false)}>
                                <div className="toggle-button-option__label">No</div>
                            </div>
                            <div className="toggle-button-slider" style={{ left: data.personalInformationInfo?.hasPreviousInternship ? '5px' : '54%' }}></div>
                        </div>
                    </div>

                    {data && data.personalInformationInfo && data.personalInformationInfo.hasPreviousInternship && (
                        <>
                            <div className="row">
                                <div className="col">
                                    <InputLabel></InputLabel>
                                    <FormControl>
                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            label="If yes, please specify (ex. 2023 Summer Internship Program"
                                            multiline
                                            required
                                            onBlur={validate}
                                            rows={4}
                                            value={data.personalInformationInfo?.trainingProgramsAttended ?? ''}
                                            inputProps={{ maxLength: 15000 }}
                                            onChange={trainingProgramsAttendedChange}
                                            error={validationErrors && errors[28].message !== ''}
                                            helperText={validationErrors && errors[28].message !== '' ? errors[28].message : ''}
                                        />
                                    </FormControl>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                <div className="d-flex justify-content-between mt-5 mb-5">
                    {data && data.personalInformationInfo && (
                        <div className="radio-button">
                            <p className="radio-button-label">Preferred NIAID research location:<span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk css-wgai2y-MuiFormLabel-asterisk"> *</span></p>
                            <RadioGroup
                                className='ml-2'
                                value={data.personalInformationInfo?.preferredNIAIDresearchLocation}
                                onChange={researchLocationChange}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                            >
                                {preferredOptions.map(option => (
                                    <FormControlLabel key={option.lookupMasterId} value={option.lookupMasterId} control={<Radio />} label={option.lookupName} />
                                ))}
                            </RadioGroup>
                            {validationErrors && errors[12].message && (
                                <FormHelperText className="text-danger">
                                    {errors[12].message}
                                </FormHelperText>
                            )}
                        </div>
                    )}

                </div>
            </form >
        </>
    );
};

export default PersonalInformation;