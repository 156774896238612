import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import httpClient from './useHttpClient';
import { SessionContext } from '../Contexts/SessionContext/SessionContext';
import useGetApiUrls from './useGetApiUrls';
import ApplicationInfo from '../Entities/ApplicationInfo';

const useGetMyApplications = (inroUserLoginName: string): [ApplicationInfo[], boolean] => {
    const { session } = useContext(SessionContext);
    const { getMyApplicationsUrl } = useGetApiUrls();

    const [applicationList, setApplicationList] = useState<ApplicationInfo[]>({} as ApplicationInfo[]);
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true);
        httpClient(session.apiToken)
            .post(getMyApplicationsUrl, inroUserLoginName, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
            .then(response => {
                setApplicationList(response.data);
            })
            .catch((ex) => {
                setLoading(false)
                console.log(ex);
            })
            .finally(() => {
                setLoading(false)
            })
        !inroUserLoginName && setApplicationList({} as ApplicationInfo[]);
    }, [inroUserLoginName]);

    return [applicationList, loading];
}

export default useGetMyApplications;