import { Dispatch, SetStateAction } from 'react';
import httpClient from '../../Common/Hooks/useHttpClient';
import Html2Pdf from '../Entities/Html2Pdf';

const useGetHtmlToPdf = (url: string, apiToken: string, fileName: string, html2Pdf: Html2Pdf, setLoading: Dispatch<SetStateAction<boolean>>): boolean => {
    setLoading(true);

    httpClient(apiToken)
        .post(url, html2Pdf, {
            responseType: 'blob'
        })
        .then((res) => {
            const blob = new Blob([res.data], { type: 'application/pdf' });

            // Create a URL for the Blob object
            const url = URL.createObjectURL(blob);

            // Trigger the download link
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();

            // Revoke the URL to release the resources
            URL.revokeObjectURL(url);
            setLoading(false);
        })
        .catch((c) => {
            setLoading(false);
         });
    return true;
};

export default useGetHtmlToPdf;