import * as React from "react";
import Unauthorized from "./Unauthorized";
import Backdrop from "./BackDrop";
import { Outlet } from "react-router";
import Welcome from "../../Login/Components/Welcome";

interface IRequiredAuthProps {
  isLoading: boolean;
  isAuthendiated: boolean;
}

const RequiredAuth: React.FunctionComponent<IRequiredAuthProps> = ({
  isAuthendiated,
  isLoading,
}) => {
  return (
    <>
      {isAuthendiated && !isLoading && <Outlet />}
      {!isAuthendiated && !isLoading && <Welcome />}
      {<Backdrop open={isLoading} />}
    </>
  );
};

export default RequiredAuth;
